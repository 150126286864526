import {
  Spaceable
} from "./chunk.LDSR6LCO.js";
import {
  to
} from "./chunk.3OP6Y2IJ.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  initializeLink
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-richtext/jb-richtext.scss?inline
var jb_richtext_default = i`@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
ul li strong,
ol li strong, slot.small p strong, .tablewrapper table thead, .tablewrapper table strong, p strong, h4 {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.tablewrapper table tr.section_header td {
  font-family: var(--theme-font-regular-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-regular-weight, 400);
}

.tablewrapper .footnotes, .tablewrapper table td > .supplementary, .tablewrapper table td.subitem, p small.mini, slot.mini p, .tablewrapper table tbody tr, .tablewrapper table figcaption, .tablewrapper table, .tablewrapper .preface, slot.small-text-regular-a-tags a, p small, slot.small p, slot.small-text-regular-a-tags p, blockquote footer,
blockquote footer p, slot.contactform p, ul li,
ol li, p, blockquote > p, h3, slot.contactform h2, h2 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h2 {
  font-size: var(--theme-typo-h2-subtitle-mobile-fontsize, 1.625rem);
  line-height: var(--theme-typo-h2-subtitle-mobile-lineheight, 2.188rem);
}
@media (min-width: 1281px) {
  h2 {
    font-size: var(--theme-typo-h2-subtitle-desktop-fontsize, 1.938rem);
    line-height: var(--theme-typo-h2-subtitle-desktop-lineheight, 2.625rem);
  }
}

h3, slot.contactform h2 {
  font-size: var(--theme-typo-h3-mobile-fontsize, 1.375rem);
  line-height: var(--theme-typo-h3-mobile-lineheight, 1.75rem);
}
@media (min-width: 1281px) {
  h3, slot.contactform h2 {
    font-size: var(--theme-typo-h3-desktop-fontsize, 1.625rem);
    line-height: var(--theme-typo-h3-desktop-lineheight, 2.25rem);
  }
}

h4 {
  font-size: var(--theme-typo-h4-mobile-fontsize, 1.188rem);
  line-height: var(--theme-typo-h4-mobile-lineheight, 1.75rem);
}
@media (min-width: 1281px) {
  h4 {
    font-size: var(--theme-typo-h4-desktop-fontsize, 1.375rem);
    line-height: var(--theme-typo-h4-desktop-lineheight, 2.125rem);
  }
}

ul li,
ol li, p, blockquote > p {
  font-size: var(--theme-typo-p-mobile-fontsize, 1.063rem);
  line-height: var(--theme-typo-p-mobile-lineheight, 1.875rem);
}
@media (min-width: 1281px) {
  ul li,
  ol li, p, blockquote > p {
    font-size: var(--theme-typo-p-desktop-fontsize, 1.188rem);
    line-height: var(--theme-typo-p-desktop-lineheight, 2.125rem);
  }
}

p strong {
  font-size: var(--theme-typo-p-highlight-mobile-fontsize, 1.063rem);
  line-height: var(--theme-typo-p-highlight-mobile-lineheight, 1.875rem);
}
@media (min-width: 1281px) {
  p strong {
    font-size: var(--theme-typo-p-highlight-desktop-fontsize, 1.188rem);
    line-height: var(--theme-typo-p-highlight-desktop-lineheight, 2.125rem);
  }
}

.tablewrapper table tbody tr, .tablewrapper table figcaption, .tablewrapper table, .tablewrapper .preface, slot.small-text-regular-a-tags a, p small, slot.small p, slot.small-text-regular-a-tags p, blockquote footer,
blockquote footer p, slot.contactform p {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 1.625rem);
}

.tablewrapper table thead, .tablewrapper table strong {
  font-size: var(--theme-typo-p-small-highlight-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-highlight-desktop-lineheight, 1.625rem);
}

.tablewrapper .footnotes, .tablewrapper table td > .supplementary, .tablewrapper table td.subitem, p small.mini, slot.mini p {
  font-size: var(--theme-typo-p-mini-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-desktop-lineheight, 1.25rem);
}

.tablewrapper table tr.section_header td {
  font-size: var(--theme-typo-p-mini-highlight-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-highlight-desktop-lineheight, 1.25rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

slot {
  display: block;
}
slot > :last-child {
  margin-block-end: var(--textarea-paragraph-margin-bottom, 0);
}

h2 {
  /* stylelint-disable */
  margin-block-start: var(--textarea-title-margin-top);
  margin-block-end: var(--textarea-title-margin-bottom, var(--spacing-lm));
  /* stylelint-enable */
}
* + h2 {
  margin-block-start: 51px;
}

slot.contactform {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, 0.8));
}
slot.contactform h2 {
  margin-block: 0;
}
slot.contactform p {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, 0.8));
}
slot.contactform p:not(:first-child) {
  margin-block-start: var(--spacing-3xs);
}
slot.contactform > :last-child {
  --textarea-paragraph-margin-bottom: 0;
}

h3 {
  margin-block-end: var(--spacing-2xs);
}

h4 {
  margin-block-end: var(--spacing-2xs);
}

blockquote {
  border-inline-start: 1px solid #b0aa7e;
  margin-bottom: var(--spacing-lm);
  padding-block: var(--spacing-3xs);
  padding-inline-start: var(--spacing-m);
}
blockquote > p {
  font-style: italic;
  margin-block-end: var(--spacing-s);
}
blockquote > p::before {
  content: "“";
}
blockquote > p::after {
  content: "”";
}
blockquote footer,
blockquote footer p {
  margin-block-end: 0;
}

p {
  margin-block-end: var(--spacing-lm);
}
slot.small-text-regular-a-tags p {
  margin-block-end: var(--textarea-p-margin-bottom, 20px);
}
slot.small p strong {
  font-size: 1rem;
  line-height: 1.625rem;
}
p.text-center {
  text-align: center;
}
p.text-right {
  text-align: end;
}

ul,
ol {
  margin-block-end: 30px;
  margin-inline-start: 16px;
  padding-inline-start: 20px;
}
@media (min-width: 960px) {
  ul,
  ol {
    margin-inline-start: 24px;
  }
}
@media (min-width: 1281px) {
  ul,
  ol {
    margin-block-end: 51px;
  }
}
ul:last-child,
ol:last-child {
  margin-block-end: 0;
}
ul {
  list-style-type: disc;
}

:is(p, ul, ol) + :is(ul, ol) {
  margin-block-start: calc(-1 * (var(--spacing-lm) - var(--spacing-3xs)));
}

a {
  box-shadow: inset 0 -1px 0 0 RGBA(var(--rgb-primary, 20, 30, 85), var(--theme-opacity-30, 0.3));
  padding-bottom: 0.15em;
  text-decoration: none;
  transition: box-shadow 0.2s;
}
a:hover {
  box-shadow: inset 0 -1px 0 0 RGB(var(--rgb-primary, 20, 30, 85));
}
slot.small-text-regular-a-tags a {
  padding-block-end: 0;
}

hr {
  border: 0;
  border-block-end: rgb(var(--rgb-primary, 20, 30, 85)) solid 1px;
}

mark {
  background-color: rgba(var(--rgb-primary, 20, 30, 85), var(--theme-opacity-10, 0.1));
}

.tablewrapper {
  margin-block-end: 28px;
}
@media (min-width: 1281px) {
  .tablewrapper {
    margin-block-end: var(--spacing-lm, 36px);
  }
}
.tablewrapper::-webkit-scrollbar {
  background-color: #141e55;
  block-size: 5px;
  inline-size: 5px;
}
.tablewrapper::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: #f1f2f2;
}
.tablewrapper::-webkit-scrollbar-thumb {
  background-color: #141e55;
  border-radius: var(--spacing-base);
  inline-size: 180px;
}
.tablewrapper::-webkit-scrollbar-track-piece:start {
  background-color: transparent;
  margin-block-start: 40px;
}
.tablewrapper .preface {
  margin-block: 30px 16px !important;
}
@media (min-width: 1281px) {
  .tablewrapper .preface {
    margin-block-start: 34px;
  }
}
.tablewrapper table {
  border-collapse: collapse;
  inline-size: 100%;
  position: relative;
  text-align: start;
}
.tablewrapper table figcaption {
  text-align: center;
}
.tablewrapper table thead {
  border-block-end: 1px solid rgb(var(--rgb-primary, 20, 30, 85));
}
.tablewrapper table thead tr th {
  color: rgb(var(--rgb-primary, 20, 30, 85)) !important;
  padding: 7px 20px 7px 0;
  text-align: start;
}
.tablewrapper table thead tr th:last-of-type {
  padding-inline-end: 0;
}
.tablewrapper table tbody tr {
  border-block-end: 1px solid rgba(var(--rgb-primary, 20, 30, 85), 0.3);
}
.tablewrapper table tbody tr th {
  text-align: start;
}
.tablewrapper table tbody tr td {
  padding: 7px 34px 7px 0;
}
.tablewrapper table tbody tr:last-of-type {
  border-block-end: none;
}
.tablewrapper table tbody td {
  padding-inline-end: 20px;
}
.tablewrapper table tbody td sup {
  inline-size: 0;
}
.tablewrapper table tbody td:last-of-type {
  padding-inline-end: 0;
}
.tablewrapper table tr.section_header {
  border-block-end-color: rgb(var(--theme-color-royal-blue, 20 30 85)/var(--theme-opacity-40, 0.4));
}
.tablewrapper table tr.section_header td {
  padding-block-start: 24px;
}
.tablewrapper table tr.section_separator {
  border-block-end-color: rgb(var(--theme-color-royal-blue, 20 30 85));
}
.tablewrapper table td.subitem {
  padding-inline-start: var(--spacing-sm);
}
.tablewrapper table td > .supplementary {
  color: rgb(var(--theme-color-royal-blue, 20 30 85)/var(--theme-opacity-80, 0.8));
  display: block;
}
.tablewrapper .footnotes {
  margin-block-start: 30px;
}
@media (min-width: 1281px) {
  .tablewrapper .footnotes {
    margin-block-start: 34px;
  }
}

u {
  box-shadow: inset 0 -1px 0 0;
  padding-block-end: 0.15em;
  text-decoration: none;
}

@media print {
  a::after {
    content: " (" attr(href) ")";
  }
}`;

// src/components/jb-richtext/jb-richtext.ts
var JbRichtext = class extends Initiable(Spaceable(r2)) {
  async firstUpdated() {
    const firstChildElement = [...Array.from(this.childNodes)].find((node) => node.nodeType === 1);
    if (firstChildElement === void 0) {
      return;
    }
    const slot = firstChildElement.assignedSlot;
    slot.append(...Array.from(this.childNodes));
    slot.querySelectorAll("table").forEach((table) => {
      const originalTable = table.cloneNode(true);
      table.replaceWith(
        this._wrapTable(
          originalTable,
          table.previousElementSibling,
          table.nextElementSibling
        )
      );
    });
    slot.querySelectorAll("a").forEach((link) => {
      initializeLink(link);
    });
  }
  _getContentSpacingProperties() {
    return { grid: "legacy", type: "text" };
  }
  _wrapTable(table, preface, footnotes) {
    const tableWrapper = document.createElement("div");
    tableWrapper.classList.add("tablewrapper");
    if (preface?.classList.contains("preface")) {
      tableWrapper.appendChild(preface);
    }
    tableWrapper.appendChild(table);
    if (footnotes?.classList.contains("footnotes")) {
      tableWrapper.appendChild(footnotes);
    }
    return tableWrapper;
  }
  render() {
    return ke`<slot class="${to(this.size)}"></slot>`;
  }
};
JbRichtext.styles = r(jb_richtext_default);
__decorateClass([
  n()
], JbRichtext.prototype, "size", 2);
JbRichtext = __decorateClass([
  customElement("jb-richtext")
], JbRichtext);

export {
  JbRichtext
};
