import {
  Spaceable
} from "./chunk.LDSR6LCO.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Linkable
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-article-row/jb-article-row.scss?inline
var jb_article_row_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

aside {
  overflow: hidden;
  position: relative;
}
aside .title_wrap {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2 * var(--row-small-margin, 6.68%));
  justify-content: center;
}
@media (min-width: 600px) and (max-width: 959px) {
  aside .title_wrap {
    width: calc(100% - 2 * 8.35%);
  }
}
@media (min-width: 1437px) {
  aside .title_wrap {
    max-width: var(--row-max-width, 1436px);
  }
  aside .title_wrap:not(.filtered-list) {
    width: 100%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1436px) {
  aside .title_wrap {
    margin: 0 6.955%;
    max-width: calc(100% - 6.955% - 6.955%);
  }
}
aside .title_content {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 0;
  width: 100%;
  margin-bottom: 60px;
}
@media (min-width: 600px) {
  aside .title_content {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 20px;
    width: 100%;
  }
}
@media (min-width: 1281px) {
  aside .title_content {
    margin-bottom: 68px;
  }
}
aside jb-section-headline {
  margin-top: 0;
}
aside .button_container {
  margin-top: 60px;
  text-align: center;
}
@media (min-width: 1281px) {
  aside .button_container {
    margin-top: 68px;
  }
}
aside .cardcontainer {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
}
aside .cardcontainer.vertical jb-article-card {
  --article-card-bottom: 34px;
  flex-basis: 100%;
}
aside .twocards jb-article-card {
  --article-card-bottom: 40px;
}
@media (min-width: 600px) {
  aside .twocards jb-article-card {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
    --article-card-bottom: 0;
  }
}
@media (min-width: 960px) {
  aside .twocards jb-article-card {
    box-sizing: border-box;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
    padding: 0 20px;
    width: 41.6666666667%;
  }
}
aside .morecards jb-article-card:last-of-type {
  --article-card-bottom: 0;
}
@media (min-width: 600px) {
  aside .morecards jb-article-card {
    --article-card-bottom: 0;
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
  }
  aside .morecards jb-article-card:nth-of-type(n + 3) {
    padding-top: 60px;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  aside .morecards jb-article-card:nth-of-type(odd) {
    padding-left: 0;
  }
  aside .morecards jb-article-card:nth-of-type(even) {
    padding-right: 0;
  }
}
@media (min-width: 960px) {
  aside .morecards jb-article-card {
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 0 20px;
    width: 33.3333333333%;
  }
  aside .morecards jb-article-card:nth-of-type(n + 3) {
    padding-top: 0;
  }
  aside .morecards jb-article-card:nth-of-type(n + 4) {
    padding-top: 68px;
  }
}

@media print {
  :host {
    break-inside: avoid;
  }
}`;

// src/components/jb-article-row/jb-article-row.ts
var JbArticleRow = class extends Linkable(Spaceable(r2)) {
  constructor() {
    super(...arguments);
    this.direction = "horizontal";
    this._isDisabled = false;
  }
  _getContentSpacingProperties() {
    return { colsMdUp: "none" };
  }
  _loadMoreArticles(event) {
    event.detail.clickEvent.preventDefault();
    this._isDisabled = true;
    fetch(this.loadMoreUrl).then((response) => response.json()).then((data) => this._populateFetchedArticleData(data));
  }
  _populateFetchedArticleData(data) {
    [...data.items].forEach((item) => {
      const newItem = {
        teaserHeader: {
          headline: item.title
        },
        teaserMeta: {
          date: item.date,
          category: item.mainTag.title,
          link: {
            href: item.mainTag.url
          },
          time: item.readingTime
        },
        link: {
          href: item.url
        }
      };
      switch (item.type) {
        case "image":
          newItem.teaserHeader.picture = {
            alt: item.title,
            imgData: item.picture
          };
          break;
        case "video":
          newItem.teaserHeader.thumbnail = {
            backgroundPicture: {
              alt: item.title,
              imgData: item.picture
            },
            aspect: "aspect_3by2",
            type: "video"
          };
          break;
        case "audio":
          newItem.teaserHeader.thumbnail = {
            backgroundPicture: {
              alt: item.title,
              imgData: item.picture
            },
            aspect: "aspect_3by2",
            type: "audio"
          };
          break;
        case "video-preview":
          newItem.teaserHeader.thumbnail = {
            videoSrc: item.previewVideo,
            type: "video",
            aspect: "aspect_3by2"
          };
          break;
        default:
          break;
      }
      this.articleCards?.push(newItem);
    });
    this._isDisabled = false;
    this.loadMoreUrl = data.nextUrl;
  }
  _renderArticles() {
    return this.articleCards.map(
      (articleCard) => ke`
        <jb-article-card
          .direction="${this.direction === "vertical" ? "horizontal" : "vertical"}"
          .link=${articleCard.link}
          .teaserHeader=${articleCard.teaserHeader}
          .teaserMeta=${articleCard.teaserMeta}
        ></jb-article-card>
      `
    );
  }
  _renderButton() {
    if (this.loadMoreUrl && this.buttonText) {
      return ke`<jb-button-secondary
        @jb-button:clicked=${this._loadMoreArticles}
        .name=${"load-more"}
        .disabled=${this._isDisabled}
        >${this.buttonText}</jb-button-secondary
      >`;
    }
    if (this.link && this.buttonText) {
      return ke`<jb-button-secondary .link=${this.link}>${this.buttonText}</jb-button-secondary>`;
    }
    return "";
  }
  render() {
    const map = {
      cardcontainer: true,
      morecards: this.direction !== "vertical" && this.articleCards.length > 2,
      twocards: this.direction !== "vertical" && this.articleCards.length <= 2,
      vertical: this.direction === "vertical"
    };
    return ke`
      <aside>
        ${this.headline ? ke`<div class="title_wrap">
              <div class="title_content">
                ${this.headline ? ke`<jb-section-headline headline="${this.headline}"></jb-section-headline>` : D}
              </div>
            </div>` : D}

        <div class="${Rt(map)}">${this._renderArticles()}</div>
        ${this.buttonText && (this.loadMoreUrl || this.link) ? ke`<div class="button_container">${this._renderButton()}</div>` : D}
      </aside>
    `;
  }
};
JbArticleRow.styles = r(jb_article_row_default);
__decorateClass([
  n({ type: Array })
], JbArticleRow.prototype, "articleCards", 2);
__decorateClass([
  n()
], JbArticleRow.prototype, "buttonText", 2);
__decorateClass([
  n()
], JbArticleRow.prototype, "direction", 2);
__decorateClass([
  n()
], JbArticleRow.prototype, "headline", 2);
__decorateClass([
  n()
], JbArticleRow.prototype, "loadMoreUrl", 2);
__decorateClass([
  r3()
], JbArticleRow.prototype, "_isDisabled", 2);
JbArticleRow = __decorateClass([
  customElement("jb-article-row")
], JbArticleRow);

export {
  JbArticleRow
};
