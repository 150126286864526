import {
  JbPopup
} from "./chunk.XLNKP7WQ.js";
import {
  JBCookie
} from "./chunk.C3CSSY4S.js";

// src/components/jb-cookie-consent/jb-cookie-consent-utils.ts
function findOriginalCookieConsent() {
  return document.querySelector("jb-cookie-consent:not([data-clone])");
}
function showCookieConsentOnPageLoad() {
  if (JBCookie.getCookie("hide_cookie_banner") && (!JBCookie.getCookie("show_cookie_banner") || JBCookie.getCookie("show_cookie_banner") === "0")) {
    return;
  }
  const originalCookieConsent = findOriginalCookieConsent();
  if (!originalCookieConsent || !originalCookieConsent.showOnPageLoad) {
    return;
  }
  const cookieConsent = originalCookieConsent.cloneNode(true);
  cookieConsent.setAttribute("data-clone", "");
  cookieConsent.open();
  new JbPopup().init({ props: { preventCloseByBackgroundClick: true }, childNodes: [cookieConsent] }).stack();
}
function bindLinkHandler() {
  document.body.addEventListener("jb-link:clicked", (event) => {
    const customEvent = event;
    if (!customEvent.detail.cookieSettings) {
      return;
    }
    customEvent.stopPropagation();
    customEvent.detail.clickEvent.preventDefault();
    customEvent.detail.clickEvent.stopPropagation();
    const originalCookieConsent = findOriginalCookieConsent();
    if (!originalCookieConsent) {
      return;
    }
    new JbPopup().init({ childNodes: [originalCookieConsent.getCookieSettings()] }).stack();
  });
}

export {
  showCookieConsentOnPageLoad,
  bindLinkHandler
};
