import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.42LHJR2F.js";
import {
  getEventElement
} from "./chunk.C3CSSY4S.js";

// src/components/jb-navigation/jb-navigation.tracking.ts
function fireTrackingEvent(element, additionalEventDetails) {
  const eventDetails = {
    event: "ce_interaction",
    component_name: "header",
    event_version: "v2",
    interaction_type: "click"
  };
  element.dispatchEvent(
    new CustomEvent("jb-tracking", {
      detail: { ...eventDetails, ...additionalEventDetails },
      bubbles: true,
      composed: true
    })
  );
}
function trackEventsRegionIndicator(element, e) {
  if (getEventElement(e, "JB-REGION-INDICATOR")) {
    const additionalEventDetails = {
      interaction_effect: "open",
      interaction_element: "location-selector",
      interaction_result: "overlay",
      interaction_text: "location-selector"
    };
    fireTrackingEvent(element, additionalEventDetails);
  }
}
function trackIconClick(element, event) {
  if (getEventElement(event, "JB-LOGO")) {
    const additionalEventDetails = {
      interaction_effect: "navigation",
      interaction_element: "logo",
      interaction_result: getEventElement(event, "JB-LOGO")?.href,
      interaction_text: "logo"
    };
    fireTrackingEvent(element, additionalEventDetails);
    return;
  }
  if (getEventElement(event, "JB-E-SERVICE-ICON")) {
    const additionalEventDetails = {
      interaction_effect: "open",
      interaction_element: "e-services",
      interaction_result: "overlay",
      interaction_text: "e-services"
    };
    fireTrackingEvent(element, additionalEventDetails);
    return;
  }
  if (getEventElement(event, "JB-SEARCH-ICON")) {
    if (element.getOverlayOpen()) {
      const additionalEventDetails2 = {
        interaction_effect: "open",
        interaction_element: "search",
        interaction_result: "overlay",
        interaction_text: "hamburger__search"
      };
      fireTrackingEvent(element, additionalEventDetails2);
      return;
    }
    const additionalEventDetails = {
      interaction_effect: "open",
      interaction_element: "search",
      interaction_result: "overlay",
      interaction_text: "search"
    };
    fireTrackingEvent(element, additionalEventDetails);
    return;
  }
  if (getEventElement(event, "JB-BURGER-ICON") && !element.getOverlayOpen()) {
    const additionalEventDetails = {
      interaction_effect: "open",
      interaction_element: "hamburger",
      interaction_result: "overlay",
      interaction_text: "hamburger"
    };
    fireTrackingEvent(element, additionalEventDetails);
  }
}
function trackLinkClick(element, e) {
  e.stopPropagation();
  if (e.detail.clickEvent) {
    e.detail.clickEvent.stopPropagation();
  }
  if (getEventElement(e, "JB-SERVICE-NAVIGATION-ITEM")) {
    if (getEventElement(e, "JB-SERVICE-NAVIGATION-ITEM")?.link?.data?.action === "e-services") {
      const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
        component_name: "header",
        interaction_effect: "open",
        interaction_element: "e-services",
        interaction_result: "overlay",
        interaction_text: "hamburger__e-services"
      });
      if (jbTrackingEvent) {
        element.dispatchEvent(jbTrackingEvent);
      }
      return;
    }
    const serviceNavigationElement = getEventElement(e, "JB-SERVICE-NAVIGATION-ITEM");
    const serviceNavigationText = serviceNavigationElement.text ?? serviceNavigationElement.trackingLabel ?? serviceNavigationElement.children[0].textContent;
    const text = serviceNavigationText.toLowerCase();
    const additionalEventDetails = {
      interaction_effect: "navigation",
      interaction_element: text.replace(/\s+/g, "-").toLowerCase(),
      interaction_result: getEventElement(e, "JB-SERVICE-NAVIGATION-ITEM")?.link?.href,
      interaction_text: text.replace(/\s+/g, "-").toLowerCase()
    };
    fireTrackingEvent(element, additionalEventDetails);
  }
}

export {
  trackEventsRegionIndicator,
  trackIconClick,
  trackLinkClick
};
