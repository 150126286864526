// src/components/jb-home-page-hero-legacy/jb-home-page-hero-legacy.snapper.ts
var HomePageHeroSnapper = class {
  constructor(items) {
    this._rects = Array.from(items).map((item) => item.getBoundingClientRect());
  }
  getCurrentItemIndexFromScrollPosition(windowScrollPosition) {
    const foundIndex = this._getScrollPositions().findIndex(
      (scrollPosition) => scrollPosition > windowScrollPosition + 1
    );
    if (foundIndex === -1) {
      return this._rects.length - 1;
    }
    return foundIndex;
  }
  getCurrentScrollProgress(windowScrollPosition) {
    const currentIndex = this.getCurrentItemIndexFromScrollPosition(windowScrollPosition);
    const previousScrollPosition = this.getSnapperPositions()[currentIndex];
    const nextScrollPosition = this.getSnapperPositions()[currentIndex + 1] || void 0;
    if (nextScrollPosition === void 0) {
      return 0;
    }
    const currentOverscroll = windowScrollPosition - previousScrollPosition;
    return currentOverscroll / (nextScrollPosition - previousScrollPosition);
  }
  getPostScrollDistance(windowScrollPosition) {
    const lastSnapperPosition = this.getSnapperPositions().pop();
    return Math.max(0, windowScrollPosition - lastSnapperPosition);
  }
  getSnapperPositions() {
    return [0, ...this._getScrollPositions()];
  }
  _getScrollPosition(index) {
    if (index < 0) {
      return 0;
    }
    const currentItemRect = this._rects[index];
    const nextItemRect = this._rects[index + 1];
    if (!nextItemRect) {
      return void 0;
    }
    const nextScrollDistance = Math.round(nextItemRect.top - currentItemRect.top);
    return nextScrollDistance + (this._getScrollPosition(index - 1) || 0);
  }
  _getScrollPositions() {
    if (!this._scrollPositions) {
      this._scrollPositions = Array.from(this._rects).map((item, index) => this._getScrollPosition(index)).filter((value) => value !== void 0);
    }
    return this._scrollPositions;
  }
};
function createFromItems(items) {
  return new HomePageHeroSnapper(items);
}

export {
  HomePageHeroSnapper,
  createFromItems
};
