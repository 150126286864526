import {
  ae
} from "./chunk.RIMVGMXQ.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  setLink
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  nlToBr
} from "./chunk.C3CSSY4S.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-contact/jb-contact.scss?inline
var jb_contact_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
h4 {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.link, p {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

p {
  font-size: var(--theme-typo-p-small-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-desktop-lineheight, 1.625rem);
}

h4 {
  font-size: var(--theme-typo-p-small-highlight-desktop-fontsize, 1rem);
  line-height: var(--theme-typo-p-small-highlight-desktop-lineheight, 1.625rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

.wrapper {
  border-top-color: rgba(20, 30, 85, 0.2); /* stylelint-disable-line */
  border-top-style: solid; /* stylelint-disable-line */
  border-top-width: var(--contact-wrapper-top-border, 1px); /* stylelint-disable-line */
  padding-bottom: var(--contact-wrapper-bottom-padding, 40px);
}

article {
  padding-top: var(--contact-article-top-padding, 40px);
  position: relative;
  z-index: 10;
}

h4 {
  color: #141e55;
  margin-bottom: 16px;
}
@media (min-width: 960px) {
  h4 {
    margin-bottom: 30px;
  }
}
* + h4 {
  margin-top: 51px;
}

.textcontent {
  flex-basis: 40%;
  flex-grow: 2;
}

.link {
  color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
}

.links {
  margin-top: 16px;
}
@media (min-width: 960px) {
  .links {
    margin-top: 30px;
  }
}

.maillink a::before {
  content: "> ";
}

jb-picture {
  height: 97px;
  margin-left: 12px;
  width: 74px;
}
@media (min-width: 960px) {
  jb-picture {
    height: 137px;
    width: 104px;
  }
}

.withimage {
  display: flex;
  justify-content: space-between;
}
.withimage article {
  flex-basis: 40%;
  flex-grow: 2;
}

p {
  color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
  margin-bottom: 0;
}
p + p {
  margin-top: 0;
}
p span {
  display: inline-block;
  margin-right: 5px;
}
p span::after {
  content: ":";
}

a {
  color: RGB(var(--rgb-primary, 20, 30, 85));
  box-shadow: inset 0 -1px 0 0 RGBA(var(--rgb-primary, 20, 30, 85), var(--theme-opacity-30, 0.3));
  padding-bottom: 0.15em;
  text-decoration: none;
  transition: box-shadow 0.2s;
}
a:hover {
  box-shadow: inset 0 -1px 0 0 RGB(var(--rgb-primary, 20, 30, 85));
}`;

// src/components/jb-contact/jb-contact.ts
var JbContact = class extends r2 {
  constructor() {
    super(...arguments);
    this.documents = [];
  }
  static _cleanPhoneNumber(input) {
    return input.trim().replace("(0)", "").replace(" ", "").replace(/[^\d#+*]/, "");
  }
  _renderDocuments() {
    return this.documents?.map((singleDocument) => {
      const anchor = document.createElement("a");
      if (singleDocument.documentLink.link) setLink(anchor, singleDocument.documentLink.link);
      anchor.innerText = (singleDocument.title ?? singleDocument.documentLink.text) + " | " + singleDocument.size;
      return ke`<p>${anchor}</p>`;
    });
  }
  static _renderLink(link) {
    const anchor = document.createElement("a");
    if (link.link) {
      setLink(anchor, link.link);
    }
    anchor.innerText = link.text ?? "";
    return ke`${anchor}`;
  }
  render() {
    return ke`
      <div class="wrapper">
        <article class="${Rt({ withimage: !!this.image })}">
          <div class="textcontent">
            <h4>${this.heading}</h4>
            ${this.address ? ke`<p>${ae(nlToBr(this.address))}</p>` : D}
            ${this.area ? ke`<p>${this.area}</p>` : D}
            ${this.position ? ke`<p>${this.position}</p>` : D}
            ${this.phoneNumber && this.phoneNumberLabel ? ke`<p>
                  <span>${this.phoneNumberLabel}</span>${JbContact._renderLink({
      text: this.phoneNumber,
      link: { href: "tel:" + JbContact._cleanPhoneNumber(this.phoneNumber) }
    })}
                </p>` : D}
            ${this.faxNumber && this.faxNumberLabel ? ke`<p><span>${this.faxNumberLabel}</span>${this.faxNumber}</p>` : D}
            ${this.documents && this.documents.length > 0 ? ke`${this._renderDocuments()}` : D}
            ${this.additionalText ? ke`<p>${ae(nlToBr(this.additionalText))}</p>` : D}
            ${this.link || this.contactLink ? ke`<p class="links">
                  ${this.contactLink ? ke`<p class="maillink">${JbContact._renderLink(this.contactLink)}</p>` : D}
                  ${this.link ? ke`<p class="maillink">${JbContact._renderLink(this.link)}</p>` : D}
                </p>` : D}
          </div>
          ${this.image && ke`<jb-picture
            .imgData=${this.image.imgData}
            ?lazy=${this.image.lazy}
          ></jb-picture>`}
        </article>
      </div>
    `;
  }
};
JbContact.styles = r(jb_contact_default);
__decorateClass([
  n()
], JbContact.prototype, "additionalText", 2);
__decorateClass([
  n()
], JbContact.prototype, "address", 2);
__decorateClass([
  n()
], JbContact.prototype, "area", 2);
__decorateClass([
  n({ type: Object })
], JbContact.prototype, "contactLink", 2);
__decorateClass([
  n({ type: Array })
], JbContact.prototype, "documents", 2);
__decorateClass([
  n()
], JbContact.prototype, "faxNumber", 2);
__decorateClass([
  n()
], JbContact.prototype, "faxNumberLabel", 2);
__decorateClass([
  n()
], JbContact.prototype, "heading", 2);
__decorateClass([
  n({ type: Object })
], JbContact.prototype, "image", 2);
__decorateClass([
  n({ type: Object })
], JbContact.prototype, "link", 2);
__decorateClass([
  n()
], JbContact.prototype, "phoneNumber", 2);
__decorateClass([
  n()
], JbContact.prototype, "phoneNumberLabel", 2);
__decorateClass([
  n()
], JbContact.prototype, "position", 2);
JbContact = __decorateClass([
  customElement("jb-contact")
], JbContact);

export {
  JbContact
};
