import {
  Spaceable
} from "./chunk.LDSR6LCO.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ChangesBackgroundColor
} from "./chunk.BAYVIPQ7.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  e,
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-teaser-block/jb-teaser-block.scss?inline
var jb_teaser_block_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.headline .subline {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

.headline .subline {
  font-size: var(--theme-typo-p-mobile-fontsize, 1.063rem);
  line-height: var(--theme-typo-p-mobile-lineheight, 1.875rem);
}
@media (min-width: 1281px) {
  .headline .subline {
    font-size: var(--theme-typo-p-desktop-fontsize, 1.188rem);
    line-height: var(--theme-typo-p-desktop-lineheight, 2.125rem);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

::slotted(*) {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 20px;
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
  padding: 0;
}
@media (min-width: 600px) {
  ::slotted(*) {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
    --article-card-bottom: 0;
  }
}
@media (min-width: 960px) {
  ::slotted(*) {
    box-sizing: border-box;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
    padding: 0 20px;
    width: 33.3333333333%;
  }
}
::slotted(*)[randomized=true] {
  display: none;
}

::slotted([slot=button]) {
  flex-basis: auto;
  margin-bottom: 0;
  padding: 0;
  width: auto;
}

.campaign ::slotted(jb-highlight-text-item) {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 20px;
  width: 100%;
}
@media (min-width: 960px) {
  .campaign ::slotted(jb-highlight-text-item) {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
  }
}

@media (min-width: 600px) {
  .four ::slotted(*) {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
  }
}

@media (min-width: 600px) {
  .four ::slotted(:nth-child(2n)) {
    padding-right: 0;
  }
}

@media (min-width: 600px) {
  .four ::slotted(:nth-child(2n-1)) {
    padding-left: 0;
  }
}

.headline {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 20px;
  width: 100%;
  margin-bottom: 68px;
}
@media (min-width: 600px) {
  .headline {
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 0 20px;
    width: 66.6666666667%;
    margin-left: 16.6666666667%;
  }
}
@media (max-width: 959px) {
  .headline {
    margin-bottom: 60px;
    padding-inline: 0;
  }
}
.headline .subline {
  margin-top: 15px;
  text-align: center;
  width: 100%;
}
@media (min-width: 960px) {
  .headline .subline {
    margin-top: 17px;
  }
}

.content {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2 * var(--row-small-margin, 6.68%));
  justify-content: center;
  max-width: 100%;
  width: 100%;
}
@media (min-width: 600px) and (max-width: 959px) {
  .content {
    width: calc(100% - 2 * 8.35%);
  }
}
@media (min-width: 1437px) {
  .content {
    max-width: var(--row-max-width, 1436px);
  }
  .content:not(.filtered-list) {
    width: 100%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1436px) {
  .content {
    margin: 0 6.955%;
    max-width: calc(100% - 6.955% - 6.955%);
  }
}
@media (min-width: 600px) {
  .content {
    max-width: initial;
    padding: 0;
    width: initial !important;
  }
}
@media (min-width: 960px) {
  .content {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (min-width: 1437px) {
  .content {
    max-width: calc(var(--row-max-width, 1436px) + 136px);
  }
}
@media (min-width: 1281px) {
  .content.four {
    box-sizing: border-box;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    padding: 0 0;
    width: 66.6666666667%;
    margin-left: 16.6666666667%;
    padding-inline: 5px;
  }
}

jb-article-card {
  --article-card-bottom: 0;
}`;

// src/components/jb-teaser-block/jb-teaser-block.ts
var JbTeaserBlock = class extends Spaceable(ChangesBackgroundColor(r2)) {
  constructor() {
    super(...arguments);
    this._shownTeasersCount = 0;
  }
  firstUpdated() {
    const curatedTeasers = this._slottedChildren.filter((element) => !element.getAttribute("randomized"));
    const randomTeasers = this._slottedChildren.filter((element) => element.getAttribute("randomized"));
    const randomizedRandomTeasers = this.shuffle(randomTeasers);
    randomizedRandomTeasers.forEach((teaser, index) => {
      if (index > this.teaserCount - curatedTeasers.length - 1) {
        teaser.style.setProperty("display", "none");
      }
    });
    const shownTeasers = this._slottedChildren.filter(
      (element) => element.style.getPropertyValue("display") !== "none"
    );
    this._shownTeasersCount = shownTeasers.length;
  }
  shuffle(array) {
    let currentIndex = array.length;
    let randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }
  _enableAnimation() {
    return !(document.querySelector("jb-info-page-hero")?.parentElement?.nextElementSibling?.querySelector("jb-teaser-block") === this);
  }
  _getContentSpacingProperties() {
    return {
      colsMdUp: 12,
      horizontal: "wide",
      type: "teaser-block"
    };
  }
  get _slottedChildren() {
    return this._slot.assignedElements({ flatten: true });
  }
  _tracking(e2) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e2, {
      component_name: "teaser-block"
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return ke`<section>
      ${(this.headline || this.subline) && ke`
          <div class="headline">
            ${this.headline && ke`<jb-section-headline .headline=${this.headline}></jb-section-headline>`}
            ${this.subline && ke`<p class="subline">${this.subline}</p>`}
          </div>
        `}
      <div class=${Rt({
      content: true,
      four: this._shownTeasersCount === 4,
      campaign: this.parentElement.nodeName === "JB-CAMPAIGN-PAGE-HERO"
    })}>
        <slot></slot>
      </div>
      <div class="content">
        <slot name="button" @jb-link:clicked=${this._tracking}></slot>
      </div>
    </div>
  </section>`;
  }
};
JbTeaserBlock.styles = r(jb_teaser_block_default);
__decorateClass([
  n()
], JbTeaserBlock.prototype, "background", 2);
__decorateClass([
  n()
], JbTeaserBlock.prototype, "headline", 2);
__decorateClass([
  n()
], JbTeaserBlock.prototype, "subline", 2);
__decorateClass([
  n({ type: Number })
], JbTeaserBlock.prototype, "teaserCount", 2);
__decorateClass([
  e(".row")
], JbTeaserBlock.prototype, "_row", 2);
__decorateClass([
  r3()
], JbTeaserBlock.prototype, "_shownTeasersCount", 2);
__decorateClass([
  e("slot")
], JbTeaserBlock.prototype, "_slot", 2);
JbTeaserBlock = __decorateClass([
  customElement("jb-teaser-block")
], JbTeaserBlock);

export {
  JbTeaserBlock
};
