import {
  scrollTo
} from "./chunk.ZHKWOFXT.js";
import {
  createFromItems
} from "./chunk.SIK7NCSO.js";
import {
  tracking1FirstDisplay,
  tracking2DiscoverMore,
  tracking3Navigation,
  tracking4OurSolutions
} from "./chunk.VF5NVPK6.js";
import {
  isMobileVP,
  isSizeMdDown
} from "./chunk.YDQDJZUC.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ChangesBackgroundColor
} from "./chunk.BAYVIPQ7.js";
import {
  debounce
} from "./chunk.C3CSSY4S.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  e,
  n,
  r as r3,
  r2 as r4
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-home-page-hero-legacy/jb-home-page-hero-legacy.scss?inline
var jb_home_page_hero_legacy_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
section .stageholder .mobile-nav ul, section .stageholder .desktop-hero .hero-item p, section .stageholder .desktop-nav ul {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

section .stageholder .mobile-nav ul li h2, section .stageholder .desktop-hero .hero-item h2 {
  font-family: var(--theme-font-xlight-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-xlight-weight, 200);
}

section .stageholder .mobile-nav ul, section .stageholder .desktop-hero .hero-item p, section .stageholder .desktop-nav ul {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, var(--theme-opacity-70, 0.7)));
  font-size: 0.813rem;
  line-height: 1.25rem;
}

section .stageholder .mobile-nav ul li h2, section .stageholder .desktop-hero .hero-item h2 {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 2.25rem;
  line-height: 2.875rem;
}
@media (min-width: 1281px) {
  section .stageholder .mobile-nav ul li h2, section .stageholder .desktop-hero .hero-item h2 {
    font-size: 2.813rem;
    line-height: 3.813rem;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  --solutions-button-height: 64px;
  --_notch-inset: calc(max(env(safe-area-inset-left), env(safe-area-inset-right)) + 5px);
}

section {
  object-fit: cover;
}
section .stageholder {
  --sidelines-top: calc(66vh - (var(--solutions-button-height) / 2));
  border-color: white;
  bottom: 0;
  display: flex;
  height: calc(100vh - var(--solutions-button-height));
  justify-content: center;
  position: sticky;
  top: 0;
}
@media (min-width: 600px) {
  section .stageholder {
    --sidelines-top: calc(50vh - (var(--solutions-button-height) / 2));
  }
}
section .stageholder .bg-image {
  background-color: #717c7d;
  height: 100vh;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  transition: opacity 600ms;
  z-index: -1;
}
section .stageholder .bg-image:first-of-type {
  opacity: 1;
}
section .stageholder .desktop-nav {
  --transform-top: -10px;
  align-items: start;
  display: none;
  flex-direction: column;
  justify-content: end;
  left: 70px;
  list-style: none;
  position: absolute;
  top: var(--sidelines-top);
  transform: translateY(var(--transform-top));
  width: 95px;
}
@media (min-width: 600px) {
  section .stageholder .desktop-nav {
    display: flex;
  }
}
section .stageholder .desktop-nav ul {
  color: rgb(var(--rgb-primary, 255, 255, 255));
  height: 100%;
  list-style: none;
}
section .stageholder .desktop-nav ul li {
  cursor: pointer;
  padding-bottom: 5px;
  position: relative;
}
section .stageholder .desktop-nav ul li::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 16 1' fill='none'%3E%3Crect y='0.125' width='10' height='1' fill='white' /%3E%3C/svg%3E");
  background: white;
  content: "";
  display: block;
  height: 1px;
  margin-left: -20px;
  position: absolute;
  top: 10px;
  width: 10px;
}
section .stageholder .desktop-hero {
  --transform-top: -40px;
  color: rgb(var(--rgb-primary, 255, 255, 255));
  display: none;
  margin: 0 auto;
  padding-right: var(--compensate-scrollbar);
  padding-top: calc(50vh - var(--solutions-button-height) / 2);
  position: absolute;
  text-align: center;
  transform: translateY(var(--transform-top));
  width: 280px;
}
@media (min-width: 600px) {
  section .stageholder .desktop-hero {
    display: block;
  }
}
@media (min-width: 960px) {
  section .stageholder .desktop-hero {
    width: 550px;
  }
}
section .stageholder .desktop-hero .hero-item {
  margin-bottom: 20vh;
  opacity: 0;
}
section .stageholder .desktop-hero .hero-item:first-of-type {
  opacity: 1;
}
section .stageholder .desktop-hero .hero-item h2 {
  font-size: 37px;
  font-size: 2.3125rem;
  line-height: 50px;
  line-height: 3.125rem;
  color: rgb(var(--rgb-primary, 255, 255, 255));
}
@media (min-width: 960px) {
  section .stageholder .desktop-hero .hero-item h2 {
    font-size: 80px;
    font-size: 5rem;
    line-height: 80px;
    line-height: 5rem;
  }
}
section .stageholder .desktop-hero .hero-item p {
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 30px;
  line-height: 1.875rem;
  color: rgb(var(--rgb-primary, 255, 255, 255));
  margin-top: 10px;
}
@media (min-width: 960px) {
  section .stageholder .desktop-hero .hero-item p {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-top: 20px;
  }
}
section .stageholder .desktop-hero .hero-item jb-button-primary {
  display: inline-block;
  margin-top: 20px;
  pointer-events: auto;
}
@media (min-width: 960px) {
  section .stageholder .desktop-hero .hero-item jb-button-primary {
    margin-top: 30px;
  }
}
section .stageholder .mobile-nav {
  --transform-top: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
  min-height: 105vh;
  padding-right: var(--compensate-scrollbar);
  position: absolute;
  top: 0;
  transform: translateY(var(--transform-top));
  width: 100%;
}
@media (min-width: 600px) {
  section .stageholder .mobile-nav {
    display: none;
  }
}
section .stageholder .mobile-nav ul {
  color: rgb(var(--rgb-primary, 255, 255, 255));
  height: 100%;
  list-style: none;
  width: calc(100vw - 100px - 80px);
}
section .stageholder .mobile-nav ul li {
  max-height: 32px;
  padding-bottom: 16px;
  position: relative;
  transition: all 600ms;
}
section .stageholder .mobile-nav ul li h2 {
  color: rgb(var(--rgb-primary, 255, 255, 255));
  cursor: pointer;
  font-size: 2.25rem;
  transform: scale(0.362);
  transform-origin: top left;
  transition: all 600ms;
}
section .stageholder .mobile-nav ul li .bullet_line {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 16 1' fill='none'%3E%3Crect y='0.125' width='10' height='1' fill='white' /%3E%3C/svg%3E");
  background: white;
  content: "";
  display: block;
  height: 1px;
  margin-left: -20px;
  position: absolute;
  top: 0.5em;
  transition: all 600ms;
  width: 10px;
}
section .stageholder .mobile-nav ul li p {
  margin-bottom: 8px;
  min-height: 10px;
  opacity: 0;
  transition: all 600ms;
}
section .stageholder .mobile-nav ul li jb-button-primary {
  margin-top: 8px;
  opacity: 0;
  pointer-events: all;
  transition: all 600ms;
  width: 100%;
}
section .stageholder .mobile-nav ul li.active {
  max-height: 174px;
}
section .stageholder .mobile-nav ul li.active h2 {
  transform: scale(1);
}
section .stageholder .mobile-nav ul li.active .bullet_line {
  top: 1.6em;
}
section .stageholder .mobile-nav ul li.active p {
  opacity: 1;
}
section .stageholder .mobile-nav ul li.active jb-button-primary {
  opacity: 1;
}
section .stageholder .mobile-nav ul li:not(.active) jb-button-primary {
  pointer-events: none;
}
section .stageholder .line-left,
section .stageholder .line-right {
  border-top: 1px solid rgb(var(--rgb-primary, 255, 255, 255));
  display: block;
  left: 0;
  position: absolute;
  top: var(--sidelines-top);
  width: 60px;
}
section .stageholder .line-right {
  left: unset;
  right: 0;
}
section .bottom-bar {
  --transform-y: 0;
  align-items: center;
  background: white;
  bottom: 0;
  display: flex;
  height: 50px;
  justify-content: center;
  max-width: 100%;
  padding-right: var(--compensate-scrollbar);
  position: fixed;
  transform: translateY(var(--transform-y));
  width: 100%;
  z-index: 2;
}
@media (min-width: 600px) {
  section .bottom-bar {
    height: var(--solutions-button-height, 64px);
  }
}
section jb-content-spacing {
  max-width: 100%;
}
section jb-picture::part(picture) {
  width: 100vw;
}`;

// src/components/jb-home-page-hero-legacy/jb-home-page-hero-legacy.ts
var JbHomePageHeroLegacy = class extends ChangesBackgroundColor(r2) {
  constructor() {
    super(...arguments);
    this.currentItemIndex = 0;
    this._indexClicked = null;
    this._indexSelected = null;
    this._isBelowFold = false;
    this._isScrollingOurSolutions = false;
    this._itemIndexForTracking = 0;
    this._settingsDesktop = {
      h2LineHeight: 80,
      maxInsetWidth: 30,
      requiredDistanceBottomBar: 60,
      requiredDistanceInsets: 50,
      sideNavItemHeight: 25
    };
    this._settingsMobile = {
      h2MinFontSize: "0.815rem",
      minItemHeight: 32,
      requiredDistanceInsets: 50,
      maxInsetsWidth: 25,
      requiredDistanceBottomBar: 60,
      transitions: []
    };
  }
  async updated(changedProperties) {
    const indexBeforeChange = changedProperties.get("_itemIndexForTracking");
    await this.updateComplete;
    if (!changedProperties.has("_itemIndexForTracking") || indexBeforeChange === void 0 || this._isScrollingOurSolutions) {
      return;
    }
    if (this._indexClicked !== null) {
      if (this._indexClicked === this._itemIndexForTracking) {
        tracking3Navigation(this, this._indexSelected ?? 0, this._indexClicked, "click");
        tracking1FirstDisplay(this, this._indexClicked);
        this._indexSelected = null;
        this._indexClicked = null;
      }
    } else {
      tracking3Navigation(this, indexBeforeChange, this._itemIndexForTracking, "swipe");
      tracking1FirstDisplay(this, this._itemIndexForTracking);
    }
  }
  async firstUpdated() {
    window.scrollTo(0, 0);
    if ("scrollRestoration" in window.history) window.history.scrollRestoration = "manual";
    this._htmlHtmlElement = document.querySelector("html");
    await this.updateComplete;
    this._screenWidth = window.innerWidth;
    this._settingsDesktop.maxInsetWidth = isSizeMdDown() ? 30 : 40;
    tracking1FirstDisplay(this);
    this._softReload();
    window.addEventListener(
      "resize",
      debounce(() => {
        if (this._screenWidth !== window.innerWidth) {
          this._softReload();
          this._screenWidth = window.innerWidth;
        }
      }, 300)
    );
  }
  _applyDesktopScrollProgress(index, effectProgress, backgroundImageProgress) {
    const item = this._domItemsDesktop[index];
    item.style.setProperty("opacity", `${effectProgress}`);
    this._bgImages[index].style.setProperty("opacity", `${backgroundImageProgress}`);
  }
  _checkNavState(isBelowFold) {
    if (this._isBelowFold === isBelowFold) return;
    this._isBelowFold = isBelowFold;
    this.dispatchEvent(
      new CustomEvent("jb-home-page-hero-legacy:stages-finished", { bubbles: true, detail: { isBelowFold } })
    );
  }
  _clamp(value) {
    return Math.max(Math.min(value, 1), 0);
  }
  _clickDiscoverMore(e2) {
    tracking2DiscoverMore(this, e2);
  }
  _clickItem(e2, index) {
    this._indexClicked = index;
    this._indexSelected = this.currentItemIndex;
    e2.preventDefault();
    scrollTo(this._getScrollTargetPosition(index));
  }
  _clickOurSolutions(evt) {
    this._isScrollingOurSolutions = true;
    evt.preventDefault();
    tracking4OurSolutions(this);
    this.renderRoot.querySelector("jb-content-spacing")?.scrollIntoView({ behavior: "smooth" });
  }
  _enableAnimation() {
    return false;
  }
  _getScrollTargetPosition(index) {
    if (isMobileVP()) {
      const totalScrollDistance = this._mobileNav.clientHeight;
      const fullPerItemScrollDistance = totalScrollDistance / (this.items.length - 1);
      return index * fullPerItemScrollDistance;
    }
    return this._snapper.getSnapperPositions()[index];
  }
  _handleScrollDesktop() {
    const currentScrollY = Math.round(window.scrollY);
    this.currentItemIndex = this._snapper.getCurrentItemIndexFromScrollPosition(currentScrollY);
    const currentScrollProgress = this._snapper.getCurrentScrollProgress(currentScrollY);
    const opacityProgress = this._clamp(20 * currentScrollProgress - 9.4);
    const postStageDistance = this._snapper.getPostScrollDistance(currentScrollY);
    this._itemIndexForTracking = opacityProgress === 1 ? this.currentItemIndex + 1 : this.currentItemIndex;
    this._domItemsDesktop.forEach((item, index) => {
      if (index === this.currentItemIndex) {
        this._applyDesktopScrollProgress(index, 1 - opacityProgress, 1);
      } else if (index === this.currentItemIndex + 1) {
        this._applyDesktopScrollProgress(index, opacityProgress, opacityProgress);
      } else {
        this._applyDesktopScrollProgress(index, 0, 0);
      }
    });
    const insetProgress = this._clamp(postStageDistance / this._settingsDesktop.requiredDistanceInsets);
    this._stage.style.setProperty("clip-path", `inset(0 ${insetProgress * this._settingsDesktop.maxInsetWidth}px)`);
    const bottomBarProgress = this._clamp(
      (postStageDistance - this._settingsDesktop.requiredDistanceInsets) / this._settingsDesktop.requiredDistanceBottomBar
    );
    this._bottomBar.style.setProperty(`--transform-y`, `${bottomBarProgress * 100}%`);
    if (postStageDistance < this._settingsDesktop.requiredDistanceInsets) {
      this._desktopHero.style.setProperty(
        "--transform-top",
        `-${currentScrollY + this._settingsDesktop.h2LineHeight / 2}px`
      );
      const navToBeGone = this.currentItemIndex * this._settingsDesktop.sideNavItemHeight + this._settingsDesktop.sideNavItemHeight * currentScrollProgress;
      this._desktopNav.style.setProperty("--transform-top", -10 - navToBeGone + "px");
    }
    if (postStageDistance - this._bottomBar.offsetHeight < 0) {
      this._checkNavState(false);
    } else {
      this._checkNavState(true);
      if (this._isScrollingOurSolutions) {
        this._isScrollingOurSolutions = false;
      }
    }
  }
  _handleScrollMobile() {
    const currentScrollY = Math.round(window.scrollY);
    const totalScrollDistance = this._mobileNav.clientHeight;
    const fullPerItemScrollDistance = totalScrollDistance / (this.items.length - 1);
    const halfPerItemScrollDistance = fullPerItemScrollDistance / 2;
    let currentScrollProgress;
    if (currentScrollY <= halfPerItemScrollDistance) {
      this.currentItemIndex = 0;
      currentScrollProgress = currentScrollY / fullPerItemScrollDistance + 0.5;
    } else if (totalScrollDistance - currentScrollY <= halfPerItemScrollDistance) {
      this.currentItemIndex = this.items.length - 1;
      currentScrollProgress = Math.min(
        0.5,
        1 + (currentScrollY - halfPerItemScrollDistance - this.currentItemIndex * fullPerItemScrollDistance) / fullPerItemScrollDistance
      );
    } else {
      this.currentItemIndex = Math.min(
        this.items.length - 1,
        1 + Math.floor((currentScrollY - halfPerItemScrollDistance) / fullPerItemScrollDistance)
      );
      currentScrollProgress = Math.min(
        1,
        1 + (currentScrollY - halfPerItemScrollDistance - this.currentItemIndex * fullPerItemScrollDistance) / fullPerItemScrollDistance
      );
    }
    const postStageDistance = currentScrollY - totalScrollDistance;
    this._domItemsMobile.forEach((item, index) => {
      if (index === this.currentItemIndex) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
      this._bgImages[index]?.style.setProperty("opacity", `${index <= this.currentItemIndex ? 1 : 0}`);
    });
    const insetProgress = this._clamp(postStageDistance / this._settingsMobile.requiredDistanceInsets);
    this._stage.style.setProperty("clip-path", `inset(0 ${insetProgress * this._settingsDesktop.maxInsetWidth}px)`);
    const buttonProgress = this._clamp(
      (postStageDistance - this._settingsMobile.requiredDistanceInsets) / this._settingsMobile.requiredDistanceBottomBar
    );
    this._bottomBar.style.setProperty("--transform-y", `${buttonProgress * 100}%`);
    const correctionFactor = -0.0575439 - 175439e-9 * window.innerHeight;
    const newTransformTop = -(currentScrollProgress + this.currentItemIndex) * this._settingsMobile.minItemHeight + window.innerHeight * correctionFactor;
    this._mobileNav.style.setProperty("--transform-top", newTransformTop + "px");
    if (currentScrollY < totalScrollDistance + this._bottomBar.offsetHeight) {
      this._checkNavState(false);
    } else {
      this._checkNavState(true);
      if (this._isScrollingOurSolutions) {
        this._isScrollingOurSolutions = false;
      }
    }
  }
  _resetDesktop() {
    const lastSnapperPosition = this._snapper.getSnapperPositions().pop();
    this._stage.style.height = `calc(100vh + ${lastSnapperPosition}px)`;
    setTimeout(() => window.scrollTo(0, 0));
  }
  _resetMobile() {
    this._stage.style.height = `calc(100vh + ${this._scrollDistanceStagesMobile}px)`;
    this._handleScrollMobile();
    setTimeout(() => window.scrollTo(0, 0));
  }
  _setState() {
    this._domItemsMobile.forEach((item) => {
      item.style.setProperty("height", "initial");
    });
    this._snapper = createFromItems(this._domItemsDesktop);
    this._itemHeightsMobile = Array.from(this._domItemsMobile).map((item) => item.getBoundingClientRect().height);
    this._scrollDistanceStagesMobile = this._mobileNav.clientHeight - this._itemHeightsMobile[0];
  }
  _softReload() {
    window.scrollTo(0, 0);
    this._setState();
    if (isMobileVP()) {
      this._resetMobile();
      window.addEventListener("scroll", () => this._handleScrollMobile(), true);
    } else {
      this._resetDesktop();
      window.addEventListener("scroll", () => this._handleScrollDesktop(), true);
    }
  }
  render() {
    return ke`
      <section>
        <div class="stage">
          <div class="stageholder">
            ${this.items.map(
      (item, index) => ke`<jb-picture
                  .alt=${item.picture.alt}
                  .aspect=${item.picture.aspect}
                  .imgData=${item.picture.imgData}
                  .lazy=${item.picture.lazy}
                  class="bg-image image-${index}"
                  fit="cover"
                ></jb-picture>`
    )}

            <div class="desktop-nav">
              <ul>
                ${this.items.map(
      (item, index) => ke`<li @click=${(e2) => this._clickItem(e2, index)}>${item.title}</li>`
    )}
              </ul>
            </div>

            <div class="mobile-nav">
              <ul>
                ${this.items.map(
      (item, index) => ke`<li>
                      <div class="bullet_line"></div>
                      <h2 @click=${(e2) => this._clickItem(e2, index)}>${item.title}</h2>
                      <p>${item.subline}</p>
                      <jb-button-primary
                        @jb-link:clicked=${this._clickDiscoverMore}
                        .link=${item.buttonLink}
                        inverted
                        >${item.buttonText}</jb-button-primary
                      >
                    </li>`
    )}
              </ul>
            </div>

            <div class="desktop-hero">
              ${this.items.map(
      (item) => ke`<div class="hero-item">
                    <h2>${item.title}</h2>
                    <p>${item.subline}</p>
                    <jb-button-primary
                      @jb-link:clicked=${this._clickDiscoverMore}
                      .link=${item.buttonLink}
                      inverted
                      >${item.buttonText}</jb-button-primary
                    >
                  </div>`
    )}
            </div>

            <div class="line-left"></div>
            <div class="line-right"></div>
          </div>
        </div>

        <div
          class="bottom-bar"
          @click=${this._clickOurSolutions}
        >
          <jb-button-tertiary arrowDirection="down">${this.teaserBlock.headline || ""}</jb-button-tertiary>
        </div>

        <jb-teaser-block
          .headline=${this.teaserBlock.headline}
          .teaserCount=${this.teaserBlock.teaserCount}
          withSpacing
        >
          <slot></slot>
        </jb-teaser-block>
      </section>
    `;
  }
};
JbHomePageHeroLegacy.styles = r(jb_home_page_hero_legacy_default);
__decorateClass([
  r3()
], JbHomePageHeroLegacy.prototype, "currentItemIndex", 2);
__decorateClass([
  n({ type: Array })
], JbHomePageHeroLegacy.prototype, "items", 2);
__decorateClass([
  n({ type: Object })
], JbHomePageHeroLegacy.prototype, "teaserBlock", 2);
__decorateClass([
  r4(".bg-image")
], JbHomePageHeroLegacy.prototype, "_bgImages", 2);
__decorateClass([
  e(".bottom-bar")
], JbHomePageHeroLegacy.prototype, "_bottomBar", 2);
__decorateClass([
  e(".desktop-hero")
], JbHomePageHeroLegacy.prototype, "_desktopHero", 2);
__decorateClass([
  e(".desktop-nav")
], JbHomePageHeroLegacy.prototype, "_desktopNav", 2);
__decorateClass([
  r4(".desktop-hero .hero-item")
], JbHomePageHeroLegacy.prototype, "_domItemsDesktop", 2);
__decorateClass([
  r4(".mobile-nav li")
], JbHomePageHeroLegacy.prototype, "_domItemsMobile", 2);
__decorateClass([
  r3()
], JbHomePageHeroLegacy.prototype, "_itemIndexForTracking", 2);
__decorateClass([
  e(".line-left")
], JbHomePageHeroLegacy.prototype, "_lineLeft", 2);
__decorateClass([
  e(".line-right")
], JbHomePageHeroLegacy.prototype, "_lineRight", 2);
__decorateClass([
  e(".mobile-nav")
], JbHomePageHeroLegacy.prototype, "_mobileNav", 2);
__decorateClass([
  e(".stage")
], JbHomePageHeroLegacy.prototype, "_stage", 2);
JbHomePageHeroLegacy = __decorateClass([
  customElement("jb-home-page-hero-legacy")
], JbHomePageHeroLegacy);

export {
  JbHomePageHeroLegacy
};
