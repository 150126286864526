import {
  LazyLoadingHandler_default
} from "./chunk.ZXFS3F4J.js";
import {
  to
} from "./chunk.3OP6Y2IJ.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  e,
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-picture/jb-picture.scss?inline
var jb_picture_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  width: 100%;
}

picture {
  display: block;
  line-height: 0;
  overflow: hidden;
  transition: transform 0.7s;
}
picture.aspect_1by1 {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
picture.aspect_1by1 > *,
picture.aspect_1by1 iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
}
picture.aspect_1by1::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: calc(1 / 1 * 100%);
  position: relative;
  width: 100%;
}
picture.aspect_2by1 {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
picture.aspect_2by1 > *,
picture.aspect_2by1 iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
}
picture.aspect_2by1::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: calc(1 / 2 * 100%);
  position: relative;
  width: 100%;
}
picture.aspect_3by2 {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
picture.aspect_3by2 > *,
picture.aspect_3by2 iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
}
picture.aspect_3by2::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: calc(2 / 3 * 100%);
  position: relative;
  width: 100%;
}
picture.aspect_16by9 {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
picture.aspect_16by9 > *,
picture.aspect_16by9 iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
}
picture.aspect_16by9::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: calc(9 / 16 * 100%);
  position: relative;
  width: 100%;
}
picture.aspect_21by9 {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
picture.aspect_21by9 > *,
picture.aspect_21by9 iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
}
picture.aspect_21by9::before {
  content: "";
  display: block;
  height: 0;
  padding-bottom: calc(9 / 21 * 100%);
  position: relative;
  width: 100%;
}

img {
  height: auto;
  transform: var(--transform-image, "");
  transition: transform 0.7s;
  width: 100%;
}

picture.contain {
  height: 100%;
}
picture.contain img {
  height: 100%;
  object-fit: contain;
}

picture.cover {
  height: 100%;
  width: 100%;
}
picture.cover img {
  height: 100%;
  object-fit: cover;
}`;

// src/components/jb-picture/jb-picture.ts
var JbPicture = class extends r2 {
  constructor() {
    super(...arguments);
    this.fit = "fill";
    this.lazy = false;
  }
  firstUpdated() {
    if (!this.lazy) {
      return;
    }
    if (document.readyState === "complete") {
      this._initLazyLoading();
    } else {
      window.addEventListener("load", () => {
        this._initLazyLoading();
      });
    }
    window.addEventListener("beforeprint", () => {
      this.lazy = false;
    });
  }
  _createTransparentImageDataUrl(width, height) {
    const gcd = (a, b) => b === 0 ? a : gcd(b, a % b);
    const roundedWidth = Math.round(width);
    const roundedHeight = Math.round(height);
    const divisor = gcd(roundedWidth, roundedHeight);
    const canvas = document.createElement("canvas");
    canvas.width = roundedWidth / divisor;
    canvas.height = roundedHeight / divisor;
    return canvas.toDataURL();
  }
  _getPlaceholderImage() {
    return this.imgData.fallbackImage.dimensions ? this._createTransparentImageDataUrl(
      this.imgData.fallbackImage.dimensions.width,
      this.imgData.fallbackImage.dimensions.height
    ) : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
  }
  _getSrcset(source) {
    const res = [];
    source.srcsets.forEach((srcset) => {
      res.push(`${srcset.uri} ${srcset.pixelDensity}x`);
    });
    return res.join(", ");
  }
  _initLazyLoading() {
    LazyLoadingHandler_default.observe(this._lazyImage, this._loadImage, "approachingViewport");
    this._lazyImage.addEventListener("load", () => {
      this.dispatchEvent(new CustomEvent("load", { bubbles: true, composed: true }));
    });
  }
  _loadImage(el) {
    if (!el.hasAttribute("data-lazy-src")) return;
    const pic = el.closest("picture");
    if (pic) {
      pic.querySelectorAll("source[data-lazy-srcset]").forEach((source) => {
        source.setAttribute("srcset", source.getAttribute("data-lazy-srcset") || "");
        source.removeAttribute("data-lazy-srcset");
      });
    }
    el.setAttribute("src", el.getAttribute("data-lazy-src") || "");
    el.removeAttribute("data-lazy-src");
  }
  _renderSources() {
    if (!this.imgData.sources) {
      return null;
    }
    if (this.lazy) {
      return Object.values(this.imgData.sources).map(
        (source) => ke`
          <source
            srcset=""
            data-lazy-srcset="${this._getSrcset(source)}"
            media="${source.mediaQuery}"
            type="${to(source.type)}"
          />
        `
      );
    }
    return Object.values(this.imgData.sources).map(
      (source) => ke`
        <source
          srcset="${this._getSrcset(source)}"
          media="${source.mediaQuery}"
          type="${to(source.type)}"
        />
      `
    );
  }
  render() {
    return ke`
      <picture
        class=${Rt({
      [`${this.fit}`]: true,
      [`${this.aspect}`]: !!this.aspect
    })}
        part="picture"
      >
        ${this._renderSources()}
        <img
          src="${this.lazy ? this._getPlaceholderImage() : this.imgData.fallbackImage.src}"
          data-lazy-src="${to(this.lazy ? this.imgData.fallbackImage.src : void 0)}"
          height="${to(this.imgData.fallbackImage.dimensions?.height)}"
          width="${to(this.imgData.fallbackImage.dimensions?.width)}"
          alt="${this.alt}"
          @load=${() => this.dispatchEvent(new CustomEvent("jb-picture:load", { bubbles: true, composed: true }))}
        />
      </picture>
    `;
  }
};
JbPicture.styles = r(jb_picture_default);
__decorateClass([
  n()
], JbPicture.prototype, "alt", 2);
__decorateClass([
  n()
], JbPicture.prototype, "aspect", 2);
__decorateClass([
  n()
], JbPicture.prototype, "fit", 2);
__decorateClass([
  n({ type: Object })
], JbPicture.prototype, "imgData", 2);
__decorateClass([
  n({ type: Boolean })
], JbPicture.prototype, "lazy", 2);
__decorateClass([
  e("img[data-lazy-src]")
], JbPicture.prototype, "_lazyImage", 2);
JbPicture = __decorateClass([
  customElement("jb-picture")
], JbPicture);

export {
  JbPicture
};
