import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-linkedin-icon/jb-linkedin-icon.scss?inline
var jb_linkedin_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  height: var(--icon-height, 15.3px);
  width: var(--icon-width, 15.4px);
}

svg {
  fill: rgb(var(--rgb-primary, 20, 30, 85));
  height: var(--icon-height, 15.3px);
  width: var(--icon-width, 15.4px);
}`;

// src/components/icons/jb-linkedin-icon/jb-linkedin-icon.ts
var JbLinkedinIcon = class extends r2 {
  render() {
    return ke`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewbox="0 0 14 14"
      >
        <path
          d="M3.813 2.395c0-.866-.692-1.565-1.546-1.565C1.413.83.72 1.529.72 2.395c0 .863.693 1.562 1.547 1.562s1.546-.7 1.546-1.562zM.973 13.542h2.615V5.067H.972zm6.795-8.475H5.26v8.475h2.51V9.094c0-1.192.546-1.902 1.593-1.902.963 0 1.425.682 1.425 1.902v4.448h2.602V8.176c0-2.272-1.282-3.368-3.074-3.368-1.793 0-2.547 1.399-2.547 1.399z"
        ></path>
      </svg>
    `;
  }
};
JbLinkedinIcon.styles = r(jb_linkedin_icon_default);
JbLinkedinIcon = __decorateClass([
  customElement("jb-linkedin-icon")
], JbLinkedinIcon);

export {
  JbLinkedinIcon
};
