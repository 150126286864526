import {
  ae
} from "./chunk.RIMVGMXQ.js";
import {
  Spaceable
} from "./chunk.LDSR6LCO.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ChangesBackgroundColor
} from "./chunk.BAYVIPQ7.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-info-block/jb-info-block.scss?inline
var jb_info_block_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.context {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.title {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

.context {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 0.688rem;
  letter-spacing: 0.063rem;
  line-height: 1rem;
}

.title {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 1.625rem;
  line-height: 2.188rem;
}
@media (min-width: 1281px) {
  .title {
    font-size: 1.938rem;
    line-height: 2.625rem;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

jb-richtext,
.context {
  margin-bottom: 16px;
}

.wrap {
  background-color: #fff;
  padding: 40px 30px;
}
@media (min-width: 960px) {
  .wrap {
    padding: 70px 11%;
  }
}

.title {
  margin-bottom: 20px;
}

.context {
  text-transform: uppercase;
}

.alternative-layout {
  text-align: center;
}
.alternative-layout jb-richtext {
  text-align: center;
}
.alternative-layout .wrap {
  padding: 0;
}`;

// src/components/jb-info-block/jb-info-block.ts
var JbInfoBlock = class extends ChangesBackgroundColor(Spaceable(r2)) {
  _getContentSpacingProperties() {
    return { align: "center", colsMdUp: 10 };
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "info-block"
    });
    if (jbTrackingEvent) this.dispatchEvent(jbTrackingEvent);
  }
  render() {
    return ke`
      <article
        class=${Rt({
      "alternative-layout": !!this.isAlternativeLayout
    })}
      >
        <div class="wrap">
          ${this.context ? ke`<h5 class="context">${this.context}</h5>` : D}
          <h3 class="title">${this.headline}</h3>
          <jb-richtext @jb-link:clicked=${this._tracking}>${ae(this.text)}</jb-richtext>
          ${this.button ? ke`<jb-button
                .link=${this.button?.link}
                .text=${this.button?.text}
                @jb-link:clicked=${this._tracking}
              ></jb-button>` : D}
        </div>
      </article>
    `;
  }
};
JbInfoBlock.styles = r(jb_info_block_default);
__decorateClass([
  n({ type: Object })
], JbInfoBlock.prototype, "button", 2);
__decorateClass([
  n()
], JbInfoBlock.prototype, "context", 2);
__decorateClass([
  n()
], JbInfoBlock.prototype, "headline", 2);
__decorateClass([
  n({ type: Boolean })
], JbInfoBlock.prototype, "isAlternativeLayout", 2);
__decorateClass([
  n()
], JbInfoBlock.prototype, "text", 2);
JbInfoBlock = __decorateClass([
  customElement("jb-info-block")
], JbInfoBlock);

export {
  JbInfoBlock
};
