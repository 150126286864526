import {
  JbButton
} from "./chunk.YBFFX5IJ.js";
import {
  ae
} from "./chunk.RIMVGMXQ.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-alert/jb-alert.scss?inline
var jb_alert_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
jb-richtext, .text-small,
.legend, h5 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h5 {
  font-family: var(--theme-font-xlight-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-xlight-weight, 200);
}

h5 {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 1.313rem;
  line-height: 2.125rem;
}
@media (min-width: 1281px) {
  h5 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  --rgb-primary: 255, 255, 255;
  display: block;
}

.wrap {
  background-color: #141e55;
  padding: 50px 98px;
  position: relative;
  text-align: center;
}
@media (min-width: 960px) and (max-width: 1280px) {
  .wrap {
    padding: 50px 74.5px 50px 71.5px;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .wrap {
    padding: 50px 80px 50px 79px;
  }
}
@media (max-width: 599px) {
  .wrap {
    padding: 50px 20px;
  }
}

.logo-wrap {
  margin-bottom: 20px;
  text-align: center;
  font-size: 0;
  font-size: 0;
}
@media (max-width: 959px) {
  .logo-wrap {
    margin-bottom: 20px;
  }
}
.logo-wrap jb-logo {
  display: inline-block;
  width: 113px;
}
@media (min-width: 1281px) {
  .logo-wrap jb-logo {
    width: 164px;
  }
}

.close-icon-wrap {
  height: 29px;
  position: absolute;
  right: 40px;
  top: 40px;
  width: 29px;
}
@media (min-width: 960px) and (max-width: 1280px) {
  .close-icon-wrap {
    height: 25px;
    right: 21px;
    top: 20px;
    width: 26px;
  }
}
@media (max-width: 959px) {
  .close-icon-wrap {
    height: 25px;
    right: 21px;
    top: 20px;
    width: 26px;
  }
}

h5 {
  color: #fff;
  margin-bottom: 9.6px;
  text-align: center;
}
@media (max-width: 1280px) {
  h5 {
    margin-bottom: 10px;
  }
}

.text-small,
.legend {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 21px;
  text-align: center;
}
@media (max-width: 1280px) {
  .text-small,
  .legend {
    margin-bottom: 20px;
  }
}
@media (max-width: 599px) {
  .text-small,
  .legend {
    margin-bottom: 19px;
  }
}

.text-small {
  line-height: 26px;
  line-height: 1.625rem;
}
@media (max-width: 1280px) {
  .text-small {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}

.legend {
  font-size: 13px;
  font-size: 0.8125rem;
}
@media (max-width: 1280px) {
  .legend {
    font-size: 11px;
    font-size: 0.6875rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}

jb-richtext {
  line-height: 26px;
  line-height: 1.625rem;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 21px;
  text-align: center;
}
@media (max-width: 1280px) {
  jb-richtext {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}`;

// src/components/jb-alert/jb-alert.ts
var JbAlert = class extends Initiable(r2) {
  static create(data) {
    const buttons = data.buttons.map(
      (option) => new JbButton().init({
        props: option,
        attributes: { slot: "buttons" }
      })
    );
    return new JbAlert().init({
      props: data,
      childNodes: [...buttons]
    });
  }
  unstacked() {
    this.remove();
  }
  _close() {
    this.dispatchEvent(new CustomEvent("jb-popup:close", { bubbles: true, composed: true }));
  }
  render() {
    return ke`
      <div class="wrap">
        <div class="logo-wrap">
          <jb-logo
            isWhite
            .svgLogo=${this.svgLogo}
          ></jb-logo>
        </div>
        <div class="close-icon-wrap">
          <jb-close-icon @click=${this._close}></jb-close-icon>
        </div>
        <h5>${this.title}</h5>
        <jb-richtext .size=${"small"}>${ae(this.content)}</jb-richtext>
        ${this.showLegend ? ke`<p class="legend">${this.legend}</p>` : D}
        <jb-button-panel .align=${"center"}><slot name="buttons"></slot></jb-button-panel>
      </div>
    `;
  }
};
JbAlert.styles = r(jb_alert_default);
__decorateClass([
  n()
], JbAlert.prototype, "content", 2);
__decorateClass([
  n()
], JbAlert.prototype, "legend", 2);
__decorateClass([
  n({ type: Boolean })
], JbAlert.prototype, "showLegend", 2);
__decorateClass([
  n()
], JbAlert.prototype, "svgLogo", 2);
__decorateClass([
  n()
], JbAlert.prototype, "title", 2);
JbAlert = __decorateClass([
  customElement("jb-alert")
], JbAlert);

export {
  JbAlert
};
