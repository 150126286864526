import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Linkable
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-service-navigation-item/jb-service-navigation-item.scss?inline
var jb_service_navigation_item_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.service-navigation-item__text {
  font-family: var(--theme-font-regular-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-regular-weight, 400);
}

.service-navigation-item__text {
  font-size: var(--theme-typo-p-mini-highlight-desktop-fontsize, 0.813rem);
  line-height: var(--theme-typo-p-mini-highlight-desktop-lineheight, 1.25rem);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-flex;
}

.service-navigation-item {
  display: flex;
  list-style: none;
  position: relative;
}
.service-navigation-item--animate-left::before {
  height: 33px;
}
.service-navigation-item--animate-left:hover::after {
  right: 27px;
  transition-delay: 0.2s;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.service-navigation-item--animate-left:hover::before {
  height: 0;
}
.service-navigation-item__text {
  color: rgb(var(--rgb-primary, 255, 255, 255));
  display: flex;
  line-height: 2.25rem;
  text-decoration: none;
}
.service-navigation-item.mobile-menu .service-navigation-item__text {
  align-items: center;
  gap: var(--spacing-3xs);
  line-height: 1rem;
}
.service-navigation-item.mobile-menu::before {
  content: none;
}
.service-navigation-item.mobile-menu::after {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
}`;

// src/components/jb-service-navigation-item/jb-service-navigation-item.ts
var JbServiceNavigationItem = class extends Linkable(r2) {
  getIconComponent(iconName) {
    switch (iconName) {
      case "contact-icon":
        return ke`<jb-contact-icon><slot></slot></jb-contact-icon>`;
      case "e-service-icon":
        return ke`<jb-e-service-icon><slot></slot></jb-e-service-icon>`;
      case "legal-icon":
        return ke`<jb-legal-icon><slot></slot></jb-legal-icon>`;
      default:
        return ke`<slot></slot>`;
    }
  }
  render() {
    return ke`
      <li
        class=${Rt({
      "mobile-menu": this.parentElement?.slot === "",
      "first-element": this.previousElementSibling === null,
      "service-navigation-item": true,
      "service-navigation-item--animate-left": true
    })}
      >
        <a
          data-linkable
          class="service-navigation-item__text"
        >
          ${this.getIconComponent(this.icon)}
        </a>
      </li>
    `;
  }
};
JbServiceNavigationItem.styles = r(jb_service_navigation_item_default);
__decorateClass([
  n()
], JbServiceNavigationItem.prototype, "icon", 2);
__decorateClass([
  n()
], JbServiceNavigationItem.prototype, "trackingLabel", 2);
JbServiceNavigationItem = __decorateClass([
  customElement("jb-service-navigation-item")
], JbServiceNavigationItem);

export {
  JbServiceNavigationItem
};
