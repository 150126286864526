const homepage = {
  navigation: null,

  init: () => {
    if (
      !document.querySelector('.t-homepage')
      || !document.querySelector('jb-home-page-hero-legacy')
    ) {
      return;
    }
    homepage.navigation = document.querySelector('jb-navigation');
    if (!homepage.navigation) {
      return;
    }
    homepage.bindEvents();
    homepage.navigation.stopAutoHide();
  },
  bindEvents: () => {
    document.addEventListener(
      'jb-home-page-hero-legacy:stages-finished',
      (e) => {
        if (e.detail.isBelowFold) {
          homepage.navigation.startAutoHide();
        } else {
          homepage.navigation.stopAutoHide();
        }
      }
    );
  },
};

export default homepage;
