import {
  Spaceable
} from "./chunk.LDSR6LCO.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ChangesBackgroundColor
} from "./chunk.BAYVIPQ7.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-contact-grid/jb-contact-grid.scss?inline
var jb_contact_grid_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
.content {
  --_grid-gap: 16px;
  --_grid-template-columns: repeat(12, 1fr);
  display: grid;
  gap: var(--grid-row-gap, 0) var(--grid-column-gap, var(--_grid-gap));
  grid-template-columns: var(--theme-grid-template-columns, var(--_grid-template-columns));
  width: 100%;
}
@media (min-width: 960px) {
  .content {
    --_grid-gap: 40px;
  }
}

:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

jb-section-headline {
  margin-bottom: 49px;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 600px) {
  jb-section-headline {
    margin-bottom: 40px;
  }
}

.content ::slotted(*) {
  grid-column: span 12;
}
@media (min-width: 600px) {
  .content ::slotted(*) {
    grid-column: span 6;
  }
}
@media (min-width: 600px) {
  .content.one ::slotted(*) {
    grid-column: 4/span 6;
  }
}`;

// src/components/jb-contact-grid/jb-contact-grid.ts
var JbContactGrid = class extends ChangesBackgroundColor(Spaceable(r2)) {
  _getContentSpacingProperties() {
    return { colsMdUp: 10, grid: "legacy" };
  }
  render() {
    return ke`
      <section>
        <div class="container">
          ${this.headline ? ke`<div class="headline"><jb-section-headline .headline=${this.headline}></jb-section-headline></div>` : D}
          <div class="${Rt({ one: this.columns === 1, two: this.columns === 2, content: true })}">
            <slot></slot>
          </div>
        </div>
      </section>
    `;
  }
};
JbContactGrid.styles = r(jb_contact_grid_default);
__decorateClass([
  n({ type: Number })
], JbContactGrid.prototype, "columns", 2);
__decorateClass([
  n()
], JbContactGrid.prototype, "headline", 2);
JbContactGrid = __decorateClass([
  customElement("jb-contact-grid")
], JbContactGrid);

export {
  JbContactGrid
};
