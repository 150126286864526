import {
  trackEventsRegionIndicator,
  trackIconClick,
  trackLinkClick
} from "./chunk.E3WEUGAZ.js";
import {
  disableBodyScroll,
  enableBodyScroll
} from "./chunk.WDF6H473.js";
import {
  JbOverlay
} from "./chunk.OGVEXABS.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  debounce,
  recenterNavItems,
  throttle
} from "./chunk.C3CSSY4S.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  e,
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-navigation/jb-navigation.scss?inline
var jb_navigation_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
  position: relative;
}

nav {
  inline-size: 100%;
  min-block-size: 60px;
  position: absolute;
  text-align: start;
  transition: background-color 0.5s ease 0.2s, transform 0.4s ease-out;
  z-index: 100;
  /* Top left navigation */
  /* Icons */
  /* Market menu */
  /* Search menu */
  /* Overlay menu */
  /* E-Service overlay */
  --rgb-primary: 255, 255, 255;
}
@media (min-width: 960px) {
  nav {
    min-block-size: 90px;
  }
}
@media (min-width: 1281px) {
  nav {
    min-block-size: 100px;
  }
}
nav a {
  color: #141e55;
}
nav .inner {
  --_notch-inset: calc(max(env(safe-area-inset-left), env(safe-area-inset-right)) + 5px);
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 24px max(20px, var(--_notch-inset)) 14px;
  transition: padding 0.5s;
}
@media (min-width: 960px) {
  nav .inner {
    padding: 35px max(40px, var(--_notch-inset)) 22px;
  }
}
@media (min-width: 1281px) {
  nav .inner {
    padding: 46px max(60px, var(--_notch-inset)) 22px;
  }
}
nav .language-zh {
  block-size: initial !important;
}
nav .top-left-navigation {
  align-items: center;
  display: flex;
  filter: drop-shadow(0 0 80px black);
  gap: var(--spacing-m);
  min-block-size: 36px;
  z-index: 500;
}
nav .middle {
  display: flex;
  justify-content: center;
  z-index: 500;
}
@media (min-width: 600px) {
  nav .middle {
    padding-inline-end: var(--compensate-scrollbar, 0);
  }
}
nav .middle ::slotted(svg) {
  block-size: auto;
}
nav .right {
  align-items: center;
  display: flex;
  filter: drop-shadow(0 0 80px black);
  justify-content: flex-end;
  z-index: 500;
}
@media (min-width: 960px) {
  nav .right {
    gap: var(--spacing-m);
  }
}
nav .mobile-search-wrap {
  display: none;
}
nav.no-animation {
  transition: none;
}
nav.no-animation:not(.overlay) {
  --svg-transition: none !important;
}
nav.no-animation .inner {
  transition: none;
}
nav.region .top-left-navigation .mobile-search-wrap {
  display: none;
}
nav.e-service .top-left-navigation .mobile-search-wrap, nav.search .top-left-navigation .mobile-search-wrap {
  display: none;
}
nav.is_hidden {
  transform: translateY(-100%);
}
nav.fixed {
  inset-block-start: 0;
  position: fixed;
}
@media (min-width: 1281px) {
  nav.fixed {
    min-block-size: 108px;
  }
}
nav.fixed:not(.transparent) {
  background-color: #141e55;
  color: #fff;
  --rgb-primary: 255, 255, 255;
  --logo-color: #fff;
}
nav.is_fixed {
  inset-block-start: 0 !important;
  position: fixed !important;
}
nav.transparent {
  background-color: transparent;
  color: white;
}
nav.transparent.fixed:not(.is_fixed) {
  background-color: #141e55;
  color: 255, 255, 255;
  --rgb-primary: 255, 255, 255;
  --logo-color: #fff;
}
nav.transparent.fixed:not(.is_fixed) .inner {
  background-color: #141e55;
}
nav.transparent + .placeholder {
  display: none;
}
nav.overlay {
  inline-size: 100%;
  position: fixed;
  transition: background-color 0.2s ease-in 0.1s;
  /* Icons */
}
nav.overlay .inner a {
  color: #fff;
}
nav.overlay nav__navigation-service-item {
  display: none;
}
@media (min-width: 1281px) {
  nav.overlay nav__navigation-service-item {
    display: inline-block;
  }
  nav.overlay nav__navigation-service-item:first-of-type {
    display: none;
  }
}
nav.overlay .mobile-search-wrap {
  display: block;
}
@media (min-width: 960px) {
  nav.overlay .mobile-search-wrap {
    display: none;
  }
}
nav.overlay-fade-out {
  transition: background-color 0.5s !important;
}
nav.overlay-fade-out nav__logo svg {
  --svg-transition: fill 0.1s ease 0.4s;
}
nav.e-service nav__mobile-search-wrap {
  display: none;
}
nav:not(nav.transparent, nav.overlay, nav.sticky) {
  --rgb-primary: 20, 30, 85;
  --logo-color: #001489;
  --svg-transition: all 0.1s ease 0.5s;
}

jb-e-service-icon {
  --icon-display: none;
}
@media (min-width: 960px) {
  jb-e-service-icon {
    --icon-display: inline-flex;
  }
}
.overlay jb-e-service-icon {
  display: none;
}

jb-search-icon {
  --display: none;
  --fill-color: rgb(var(--rgb-primary, 255, 255, 255));
}
@media (min-width: 960px) {
  jb-search-icon {
    --display: inline-flex;
  }
}
.overlay jb-search-icon {
  --display: none;
}
.mobile-search-wrap jb-search-icon {
  margin-block-start: 8px;
  margin-inline-start: 2px;
  --display: inline-block;
}
@media (min-width: 960px) {
  .mobile-search-wrap jb-search-icon {
    --display: none;
  }
}

.overlay-fade-out jb-burger-icon {
  --bar-transition: transform 0.2s linear, border-color 0.1s ease 0.4s;
}

jb-burger-icon {
  padding-inline-end: var(--compensate-scrollbar, 0);
}
@media (min-width: 1281px) {
  jb-burger-icon {
    margin-inline-start: calc(var(--spacing-xl) - var(--spacing-m));
  }
}

::slotted(jb-logo) {
  block-size: 22px;
  inline-size: 114px;
}
@media (min-width: 960px) {
  ::slotted(jb-logo) {
    block-size: 32px;
    inline-size: 164px;
  }
}
.overlay-fade-out ::slotted(jb-logo) {
  --svg-transition: fill 0.1s ease 0.4s;
}

::slotted(jb-service-navigation) {
  --display: none;
}
@media (min-width: 960px) {
  ::slotted(jb-service-navigation) {
    --display: flex;
  }
}
.overlay ::slotted(jb-service-navigation) {
  --display: none;
}
@media (min-width: 1281px) {
  .overlay ::slotted(jb-service-navigation) {
    --display: flex;
  }
}
.search ::slotted(jb-service-navigation), .e-service ::slotted(jb-service-navigation), .region ::slotted(jb-service-navigation) {
  --display: none;
}

.overlay ::slotted(jb-region-indicator) {
  display: none;
  --display: none;
}
.region ::slotted(jb-region-indicator) {
  --before-height: 0;
  --after-right: 30px;
  --display: flex;
}

@media print {
  .inner {
    justify-content: center;
  }
  nav .top-left-navigation,
  nav .right {
    display: none;
  }
}`;

// src/components/jb-navigation/jb-navigation.ts
var JbNavigation = class extends r2 {
  constructor() {
    super(...arguments);
    this.isTransparent = false;
    this._animateHiding = true;
    this._fixed = false;
    this._hidden = false;
    this._isAutohiding = true;
    this._overlayOpen = null;
    this._previousScrollposition = 0;
    this._scrollDistance = 0;
  }
  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(
      "scroll",
      debounce(() => {
        this._scrollMainNavigation();
      }, 10)
    );
    if (!this.isTransparent) return;
    document.body.style.setProperty("position", "relative");
    const shadeElm = document.createElement("div");
    const blurElm = document.createElement("div");
    const gradientElm = document.createElement("div");
    shadeElm.style.position = "absolute";
    shadeElm.style.top = "0";
    shadeElm.style.width = "100%";
    shadeElm.style.zIndex = "10";
    blurElm.style.cssText = `
      display: block;
      inset: 0;
      pointer-events: none;
      position: absolute;
      backdrop-filter: blur(2px);
      height: 140px;
      mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    `;
    gradientElm.style.cssText = `
      display: block;
      inset: 0;
      pointer-events: none;
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      height: 200px;
    `;
    shadeElm.appendChild(blurElm);
    shadeElm.appendChild(gradientElm);
    document.body.appendChild(shadeElm);
  }
  async updated(_changedProperties) {
    await this.updateComplete;
    if (this._logo) {
      this._logo.isWhite = !!(this.isTransparent || this._fixed || this._overlayOpen);
    }
    if (_changedProperties.has("_hidden") || _changedProperties.has("_fixed")) {
      this.dispatchEvent(
        new CustomEvent("jb-navigation:appearance-changed", {
          bubbles: true,
          composed: true,
          detail: {
            fixed: this._fixed,
            hidden: this._hidden
          }
        })
      );
    }
    if (this._overlayOpen) {
      disableBodyScroll(this, { allowTouchMove: () => true });
    } else {
      enableBodyScroll(this);
    }
    if (_changedProperties.has("_overlayOpen")) {
      if (this._overlayOpen) {
        this.style.zIndex = (this._getHighestOverlayZIndex() + 1).toString();
      } else {
        this.style.zIndex = "unset";
      }
      recenterNavItems();
      this._setBurgerIconState();
    }
  }
  async firstUpdated() {
    this._serviceNavigation = document.querySelector("jb-service-navigation") ?? void 0;
    this._logo = document.querySelector("jb-logo") ?? void 0;
    this._placeholder = document.createElement("div");
    this._navigation?.after(this._placeholder);
    this._placeholder.classList.add("placeholder");
    this._placeholder.style.setProperty("height", this._navigation.clientHeight + "px");
    this._languageDropdown = this.querySelector("jb-dropdown");
    window.addEventListener(
      "resize",
      debounce(() => {
        this._placeholder.style.setProperty("height", this._navigation.clientHeight + "px");
      }, 10)
    );
    document.addEventListener("jb-link:clicked", (e2) => {
      e2.preventDefault();
      this._maybeOpenEServiceOverlay(e2);
    });
    document.addEventListener("jb-overlay:close", () => {
      if (this._overlayOpen) this._toggleMenuOverlay();
    });
    document.addEventListener(
      "burger-icon:clicked",
      throttle(() => {
        this._toggleMenuOverlay();
      }, 500)
    );
    this._menuComponent = this._initMenu();
    window.setTimeout(() => {
      import("../components/jb-e-service-overlay/jb-e-service-overlay.js").then();
      import("../components/jb-region-select-overlay/jb-region-select-overlay.js").then();
      if (this.searchOverlay) {
        import("../components/jb-search-overlay/jb-search-overlay.js").then();
      }
    }, 50);
  }
  getOverlayOpen() {
    return this._overlayOpen;
  }
  startAutoHide() {
    this._hidden = true;
    this._isAutohiding = true;
  }
  stopAutoHide() {
    this._hidden = false;
    this._isAutohiding = false;
  }
  _checkOpenEService() {
    return this._overlayOpen === "eService";
  }
  _checkOpenMenu() {
    return this._overlayOpen === "menu";
  }
  _checkOpenRegionSelect() {
    return this._overlayOpen === "regionSelect";
  }
  _checkOpenSearch() {
    return this._overlayOpen === "search";
  }
  async _findAnimatedElement(overlay, element, contentChildren) {
    if (element && overlay) {
      await customElements.whenDefined(element);
      const animatedElement = overlay.querySelector(element);
      if (contentChildren) {
        animatedElement.contentChildren = Array.from(animatedElement.shadowRoot.querySelectorAll(contentChildren));
      }
      return animatedElement;
    }
    return null;
  }
  _getHighestOverlayZIndex() {
    const overlays = Array.from(document.querySelectorAll("jb-overlay"));
    const overlaysIndex = [];
    overlays.map((overlay) => overlaysIndex.push(overlay.zIndex()));
    return Math.max(...overlaysIndex);
  }
  async _initMenu() {
    const { JbMenu } = await import("../components/jb-menu/jb-menu.js");
    return new JbMenu().init({ props: { ...this.menu } });
  }
  _initOverlay(childNode) {
    return new JbOverlay().init({
      props: { color: "blue", hasLogo: false, hasCloseIcon: false, newAnimation: true },
      childNodes: [childNode]
    });
  }
  async _initRegionOverlay() {
    const { JbRegionSelectOverlay } = await import("../components/jb-region-select-overlay/jb-region-select-overlay.js");
    return new JbRegionSelectOverlay().init({ props: { ...this.regionSelectOverlay } });
  }
  async _initSearchOverlay() {
    const { JbSearchOverlay } = await import("../components/jb-search-overlay/jb-search-overlay.js");
    return new JbSearchOverlay().init({ props: { ...this.searchOverlay } });
  }
  _maybeOpenEServiceOverlay(customEvent) {
    if (customEvent.detail.action === "e-services") {
      customEvent.stopPropagation();
      customEvent.detail.clickEvent.preventDefault();
      customEvent.detail.clickEvent.stopPropagation();
      this._openEServiceOverlay().then();
    }
  }
  async _openEServiceOverlay() {
    if (this.eServiceOverlay === void 0) {
      return;
    }
    const { JbEServiceOverlay } = await import("../components/jb-e-service-overlay/jb-e-service-overlay.js");
    const eServiceOverlay = new JbEServiceOverlay().init({ props: { ...this.eServiceOverlay } });
    if (this._checkOpenMenu()) {
      this._overlayFront = this._initOverlay(eServiceOverlay);
      this._overlayFront.stack();
      this._findAnimatedElement(this._overlayFront, "jb-e-service-overlay", "div.eservices").then(async (element) => {
        this._overlayOpen = "eService";
        this._serviceNavigation.hideItems = "all";
        await element?.reveal?.();
      });
    } else {
      this._overlayBack = this._initOverlay(eServiceOverlay);
      this._overlayBack.stack();
      this._findAnimatedElement(this._overlayBack, "jb-e-service-overlay", "div.eservices").then(async (element) => {
        this._overlayOpen = "eService";
        await element?.reveal?.();
      });
    }
  }
  async _openSearchOverlay() {
    if (this._checkOpenMenu()) {
      this._overlayFront = this._initOverlay(await this._initSearchOverlay());
      this._overlayFront.stack();
      this._findAnimatedElement(this._overlayFront, "jb-search-overlay", "jb-search-input").then(async (element) => {
        this._overlayOpen = "search";
        this._serviceNavigation.hideItems = void 0;
        await element?.reveal?.();
      });
    } else {
      this._overlayBack = this._initOverlay(await this._initSearchOverlay());
      this._overlayBack.stack();
      this._findAnimatedElement(this._overlayBack, "jb-search-overlay", "jb-search-input").then(async (element) => {
        this._overlayOpen = "search";
        await element?.reveal?.();
      });
    }
  }
  _scrollMainNavigation() {
    if (this._overlayOpen || !this._isAutohiding) {
      return;
    }
    const scrollUpToggleDistance = 100;
    const minimumOffsetYtoShow = 500;
    const minimumOffsetYtoAnimate = 300;
    const { pageYOffset } = window;
    const scrollDirection = pageYOffset <= this._previousScrollposition ? "up" : "down";
    if (pageYOffset > this._navigation.offsetHeight) {
      this._fixed = true;
      this._hidden = true;
      if (this._languageDropdown) {
        this._languageDropdown.inverted = false;
      }
    } else {
      this._fixed = false;
      this._hidden = false;
      if (this._languageDropdown) {
        this._languageDropdown.inverted = true;
      }
    }
    if (scrollDirection === "up") {
      this._scrollDistance += window.scrollY - this._previousScrollposition;
      if (pageYOffset > minimumOffsetYtoShow && Math.abs(this._scrollDistance) > scrollUpToggleDistance) {
        this._hidden = false;
      }
      this._animateHiding = pageYOffset <= minimumOffsetYtoAnimate;
    } else {
      if (pageYOffset > this._navigation.offsetHeight) {
        this._hidden = true;
      }
      this._scrollDistance = 0;
    }
    this._previousScrollposition = pageYOffset <= 0 ? 0 : pageYOffset;
  }
  _setBurgerIconState() {
    this._burgerIcon.isOpen = !!this._overlayOpen;
  }
  async _toggleMenuOverlay() {
    if (this._overlayFront) {
      if (this._overlayOpen === "search") {
        await this._findAnimatedElement(this._overlayFront, "jb-search-overlay", "jb-search-input").then(
          async (element) => {
            await element?.hide?.();
          }
        );
      }
      if (this._overlayOpen === "eService") {
        await this._findAnimatedElement(this._overlayFront, "jb-e-service-overlay", "div.eservices").then(
          async (element) => {
            await element?.hide?.();
          }
        );
      }
      this._overlayFront.unstack();
      this._overlayFront = void 0;
      this._serviceNavigation.hideItems = "first";
      this._overlayOpen = "menu";
      return;
    }
    if (!this._overlayOpen) {
      this._overlayBack = this._initOverlay(await this._menuComponent);
      this._overlayBack.stack();
      if (this._overlayBack?.querySelector("jb-menu")?.shadowRoot?.querySelector(".active")) {
        await this._findAnimatedElement(this._overlayBack, "jb-menu", ".secondary-wrapper").then(async (element) => {
          element.contentDuration = 0;
          element.wrapperDuration = 0;
          await element?.reveal?.();
        });
      }
      await this._findAnimatedElement(
        this._overlayBack,
        "jb-menu",
        ".primary-wrapper jb-menu-item, .primary-wrapper jb-service-navigation"
      ).then(async (element) => {
        this._overlayOpen = "menu";
        this._serviceNavigation.hideItems = "first";
        element.contentDuration = 300;
        element.wrapperDuration = 350;
        await element?.reveal?.();
      });
    } else {
      if (this._overlayOpen === "search") {
        await this._findAnimatedElement(this._overlayBack, "jb-search-overlay", "jb-search-input").then(
          async (element) => {
            await element?.hide?.();
          }
        );
      }
      if (this._overlayOpen === "eService") {
        await this._findAnimatedElement(this._overlayBack, "jb-e-service-overlay", "div.eservices").then(
          async (element) => {
            await element?.hide?.();
          }
        );
      }
      if (this._overlayOpen === "menu") {
        if (this._overlayBack?.querySelector("jb-menu")?.shadowRoot?.querySelector(".active")) {
          await this._findAnimatedElement(this._overlayBack, "jb-menu", ".secondary-wrapper").then(async (element) => {
            await element?.hide?.();
          });
        } else {
          await this._findAnimatedElement(
            this._overlayBack,
            "jb-menu",
            ".primary-wrapper jb-menu-item, .primary-wrapper jb-service-navigation"
          ).then(async (element) => {
            await element?.hide?.();
          });
        }
      }
      if (this._overlayOpen === "regionSelect") {
        await this._findAnimatedElement(
          this._overlayBack,
          "jb-region-select-overlay",
          ".region-select-overlay__wrap"
        ).then(async (element) => {
          await element?.hide?.();
        });
      }
      this._overlayBack.unstack();
      this._overlayOpen = null;
      this._serviceNavigation.hideItems = "all-except-first";
    }
  }
  async _toggleRegionOverlay(e2) {
    if (!this._overlayOpen) {
      trackEventsRegionIndicator(this, e2);
      this._overlayBack = this._initOverlay(await this._initRegionOverlay());
      this._overlayBack.stack();
      this._findAnimatedElement(this._overlayBack, "jb-region-select-overlay", ".region-select-overlay__wrap").then(
        async (element) => {
          this._overlayOpen = "regionSelect";
          this._serviceNavigation.hideItems = "all";
          await element?.reveal?.();
        }
      );
    } else {
      this._findAnimatedElement(this._overlayBack, "jb-region-select-overlay", ".region-select-overlay__wrap").then(
        async (element) => {
          await element?.hide?.();
          this._overlayBack.unstack();
          this._serviceNavigation.hideItems = "all-except-first";
          this._overlayOpen = null;
        }
      );
    }
  }
  render() {
    return ke`
      <nav
        role="navigation"
        aria-expanded="${this._overlayOpen ? "true" : "false"}"
        aria-label="${this.ariaLabelNavigation ?? "Navigation"}"
        class=${Rt({
      transparent: !!this.isTransparent,
      menu: !!this._overlayOpen,
      overlay: !!this._overlayOpen,
      "e-service": this._checkOpenEService(),
      search: this._checkOpenSearch(),
      region: this._checkOpenRegionSelect(),
      "no-animation": this._animateHiding,
      is_hidden: this._hidden,
      fixed: this._fixed,
      is_fixed: !this._isAutohiding
    })}
        @region-indicator:clicked=${this._toggleRegionOverlay}
        @search-icon:clicked=${this._openSearchOverlay}
        @jb-link:clicked=${(e2) => trackLinkClick(this, e2)}
      >
        <div class="inner">
          <div class="top-left-navigation">
            <div class="${Rt({ hidden: this._checkOpenSearch(), "mobile-search-wrap": true })}">
              ${this.searchOverlay ? ke` <jb-search-icon
                    aria-label="${this.ariaLabelSearch ?? "Search"}"
                    role="button"
                    @click=${(e2) => trackIconClick(this, e2)}
                  ></jb-search-icon>` : D}
            </div>
            <slot name="left"></slot>
          </div>
          <div
            class="middle"
            part="logo"
          >
            <slot
              name="middle"
              @click=${(e2) => trackIconClick(this, e2)}
            ></slot>
          </div>
          <div
            class="right"
            @e-service-icon:clicked=${this._openEServiceOverlay}
          >
            ${this.eServiceOverlay && ke`<jb-e-service-icon
              aria-label="${this.ariaLabelEServices ?? "E-Services"}"
              role="button"
              @click=${(e2) => trackIconClick(this, e2)}
              >${this.eServiceLabel ?? D}</jb-e-service-icon
            >`}
            ${this.searchOverlay ? ke`<jb-search-icon
                  aria-label="${this.ariaLabelSearch ?? "Search"}"
                  role="button"
                  @click=${(e2) => trackIconClick(this, e2)}
                  >${this.searchLabel ?? D}</jb-search-icon
                >` : D}
            <jb-burger-icon
              aria-label="${this.ariaLabelMenu ?? "Menu"}"
              role="button"
              @click=${(e2) => trackIconClick(this, e2)}
            ></jb-burger-icon>
          </div>
        </div>
      </nav>
    `;
  }
};
JbNavigation.styles = r(jb_navigation_default);
__decorateClass([
  n()
], JbNavigation.prototype, "ariaLabelEServices", 2);
__decorateClass([
  n()
], JbNavigation.prototype, "ariaLabelMenu", 2);
__decorateClass([
  n()
], JbNavigation.prototype, "ariaLabelNavigation", 2);
__decorateClass([
  n()
], JbNavigation.prototype, "ariaLabelSearch", 2);
__decorateClass([
  n()
], JbNavigation.prototype, "eServiceLabel", 2);
__decorateClass([
  n({ type: Object })
], JbNavigation.prototype, "eServiceOverlay", 2);
__decorateClass([
  n({ type: Boolean })
], JbNavigation.prototype, "isTransparent", 2);
__decorateClass([
  n({ type: Object })
], JbNavigation.prototype, "menu", 2);
__decorateClass([
  n({ type: Object })
], JbNavigation.prototype, "regionSelectOverlay", 2);
__decorateClass([
  n()
], JbNavigation.prototype, "searchLabel", 2);
__decorateClass([
  n({ type: Object })
], JbNavigation.prototype, "searchOverlay", 2);
__decorateClass([
  r3()
], JbNavigation.prototype, "_animateHiding", 2);
__decorateClass([
  e("jb-burger-icon")
], JbNavigation.prototype, "_burgerIcon", 2);
__decorateClass([
  r3()
], JbNavigation.prototype, "_fixed", 2);
__decorateClass([
  r3()
], JbNavigation.prototype, "_hidden", 2);
__decorateClass([
  r3()
], JbNavigation.prototype, "_isAutohiding", 2);
__decorateClass([
  e("nav")
], JbNavigation.prototype, "_navigation", 2);
__decorateClass([
  r3()
], JbNavigation.prototype, "_overlayOpen", 2);
JbNavigation = __decorateClass([
  customElement("jb-navigation")
], JbNavigation);

export {
  JbNavigation
};
